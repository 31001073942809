<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Lengkap</label><span class="red--text text-subtitle-1">*</span>
                  </div>
                  <v-text-field
                    id="name"
                    :readonly="!isEdit"
                    v-model="input.name"
                    dense
                    placeholder="Masukkan Nama Lengkap"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="nik">NIK</label><span class="red--text text-subtitle-1">*</span>
                  </div>
                  <v-text-field
                    id="nik"
                    :readonly="!isEdit"
                    @keypress="onlyNumber"
                    @keyup="extractNIK()"
                    v-model="input.nik"
                    dense
                    placeholder="Masukkan Nomor KTP"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="birthDate">Tanggal Lahir</label>
                    <span class="red--text text-subtitle-1">*</span>
                  </div>

                  <v-menu
                    ref="menu"
                    :disabled="!isEdit"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="input.birth_date"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        id="birthDate"
                        dense
                        placeholder="Masukkan Tanggal Lahir"
                        class="detail-input__input-text"
                        required
                        readonly
                        :clearable="isEdit"
                        :loading="loading"
                        v-bind="attrs"
                        v-on="on"
                        :value="dateFormattedMomentjs"
                        @click:clear="input.birth_date = null"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      :disabled="!isEdit"
                      @click:date="
                        $refs.menu.save(input.birth_date)
                        menu = false
                      "
                      v-model="input.birth_date"
                      locale="id-ID"
                      no-title
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.menu.save(input.birth_date)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="province">Provinsi</label>
                  </div>
                  <v-combobox
                    id="province"
                    :readonly="!isEdit"
                    v-model="selectedProvince"
                    dense
                    placeholder="Pilih Provinsi"
                    class="detail-input__input-region"
                    :items="resource.province"
                    :loading="resource.loadingProvince"
                    return-object
                    :search-input.sync="searchProv"
                    clearable
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Provinsi "<strong>{{ searchProv }}</strong
                            >" tidak ditemukan.
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ message.region_not_found }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  <!-- <v-icon color="primary" @click="addRegion(0, searchProv)">mdi-plus</v-icon> -->
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="regency">Kabupaten</label>
                  </div>
                  <v-combobox
                    id="regency"
                    :readonly="!isEdit"
                    :disabled="!selectedProvince"
                    v-model="selectedRegency"
                    dense
                    placeholder="Pilih Kabupaten"
                    class="detail-input__input-region"
                    :items="resource.regency"
                    :loading="resource.loadingRegency"
                    return-object
                    :search-input.sync="searchReg"
                    clearable
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Kabupaten "<strong>{{ searchReg }}</strong
                            >" tidak ditemukan.
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ message.region_not_found }}
                          </v-list-item-subtitle>
                          <!-- <v-btn color="primary" @click="addRegion(1, searchReg)">Tambah Baru</v-btn> -->
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>
                  <v-icon
                    :disabled="!selectedProvince || !isEdit"
                    color="primary"
                    @click="addRegion(1, searchReg)"
                    >mdi-plus</v-icon
                  >
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="district">Kecamatan</label>
                  </div>
                  <v-combobox
                    id="district"
                    :readonly="!isEdit"
                    :disabled="!selectedRegency"
                    v-model="selectedDistrict"
                    dense
                    placeholder="Pilih Kecamatan"
                    class="detail-input__input-region"
                    :items="resource.district"
                    :loading="resource.loadingDistrict"
                    return-object
                    :search-input.sync="searchDis"
                    clearable
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Kecamatan "<strong>{{ searchDis }}</strong
                            >" tidak ditemukan.
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ message.region_not_found }}
                          </v-list-item-subtitle>
                          <!-- <v-btn color="primary" @click="addRegion(2, searchDis)">Tambah Baru</v-btn> -->
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>

                  <v-icon
                    :disabled="!selectedRegency || !isEdit"
                    color="primary"
                    @click="addRegion(2, searchDis)"
                    >mdi-plus</v-icon
                  >
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="subdistrict">Kelurahan</label>
                  </div>
                  <v-combobox
                    id="subdistrict"
                    :readonly="!isEdit"
                    :disabled="!selectedDistrict"
                    v-model="selectedSubdistrict"
                    dense
                    placeholder="Pilih Kelurahan"
                    class="detail-input__input-region"
                    :items="resource.subdistrict"
                    :loading="resource.loadingSubdistrict"
                    return-object
                    :search-input.sync="searchSubDis"
                    clearable
                  >
                    <template v-slot:no-data>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>
                            Kelurahan "<strong>{{ searchSubDis }}</strong
                            >" tidak ditemukan.
                          </v-list-item-title>
                          <v-list-item-subtitle>
                            {{ message.region_not_found }}
                          </v-list-item-subtitle>
                          <!-- <v-btn color="primary" @click="addRegion(3, searchSubDis)">Tambah Baru</v-btn> -->
                        </v-list-item-content>
                      </v-list-item>
                    </template>
                  </v-combobox>

                  <v-icon
                    :disabled="!selectedDistrict || !isEdit"
                    color="primary"
                    @click="addRegion(3, searchSubDis)"
                    >mdi-plus</v-icon
                  >
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="address">Alamat Lengkap</label>
                  </div>
                  <v-textarea
                    v-model="input.address.description"
                    id="address"
                    :readonly="!isEdit"
                    class="detail-input__input-text align-start"
                    height="100"
                    outlined
                  />
                </div>
              </v-col>
              <v-col md="2"></v-col>
              <v-col md="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="religion">Agama</label>
                  </div>
                  <v-select
                    id="religion"
                    :readonly="!isEdit"
                    v-model="input.religion"
                    dense
                    required
                    :loading="loading"
                    placeholder="Pilih Agama"
                    class="detail-input__input-text"
                    :items="resource.religion"
                  ></v-select>
                </div>

                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="phone">No Telepon</label>
                  </div>
                  <v-text-field
                    id="phone"
                    :readonly="!isEdit"
                    @keypress="onlyNumber"
                    v-model="input.phone"
                    dense
                    placeholder="Masukkan No Telepon"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="profession">Pekerjaan</label>
                  </div>
                  <v-text-field
                    id="profession"
                    :readonly="!isEdit"
                    v-model="input.profession"
                    dense
                    placeholder="Masukkan Pekerjaan Peserta"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="businessType">Jenis Usaha</label>
                  </div>
                  <v-text-field
                    id="businessType"
                    :readonly="!isEdit"
                    v-model="input.business.type"
                    dense
                    placeholder="Masukkan Jenis Usaha"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="businessAddress">Alamat Usaha</label>
                  </div>
                  <v-text-field
                    id="businessAddress"
                    :readonly="!isEdit"
                    v-model="input.business.address"
                    dense
                    placeholder="Masukkan Alamat Usaha"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions v-if="isEdit">
      <v-spacer></v-spacer>
      <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
        Kembali
      </v-btn>
      <v-btn color="primary" width="100" :loading="loadingSave" @click="saveMember()" large>
        Simpan
      </v-btn>
    </v-card-actions>
    <v-dialog v-if="dialogRegion" v-model="dialogRegion" persistent max-width="300px">
      <region-component
        :id_province="selectedProvince"
        :id_regency="selectedRegency"
        :id_district="selectedDistrict"
        :id_subdistrict="selectedSubdistrict"
        :region-name="regionName"
        :regionType="regionType"
        @close-form="onRegionClose"
        @save-form="onRegionSave"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

import RegionComponent from './Region.vue'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  components: { RegionComponent },
  mixins: [ConstantMixin],
  props: {
    id_member: String,
    isEdit: Boolean
  },
  data: () => ({
    title: 'Daftar Anggota Baru',
    loading: false,
    loadingCompany: true,
    loadingSave: false,
    dialog: false,
    dialogDelete: false,
    dialogRegion: false,
    activeRadio: '',
    isNew: true,
    isAdmin: false,
    menu: false,
    searchProv: '',
    searchReg: '',
    searchDis: '',
    searchSubDis: '',
    regionName: '',
    regionType: '',
    input: {
      id_company: '',
      nik: '',
      name: '',
      birth_date: null,
      religion: 'islam',
      profession: '',
      address: {
        province: '',
        regency: '',
        district: '',
        subdistrict: '',
        description: ''
      },
      phone: '',
      business: {
        type: '',
        address: ''
      },
      isActive: true,
      timestamps: {
        created_by: ''
      }
    },
    selectedProvince: '',
    selectedRegency: '',
    selectedDistrict: '',
    selectedSubdistrict: '',
    resource: {
      loadingProvince: true,
      loadingRegency: false,
      loadingDistrict: false,
      loadingSubdistrict: false,
      province: [],
      regency: [],
      district: [],
      subdistrict: [],
      religion: [
        { text: 'Budha', value: 'budha' },
        { text: 'Hindu', value: 'hindu' },
        { text: 'Islam', value: 'islam' },
        { text: 'Katolik', value: 'katolik' },
        { text: 'Konghucu', value: 'konghucu' },
        { text: 'Kristen', value: 'kristen' }
      ]
    },
    message: {
      region_not_found: 'Silahkan Hubungi Administrator untuk menambahkan wilayah baru'
    }
  }),
  computed: {
    dateFormattedMomentjs() {
      return this.input.birth_date
        ? moment(this.input.birth_date)
            .locale('id')
            .format('D MMMM YYYY')
        : null
    }
  },
  watch: {
    selectedProvince: function(val) {
      this.input.address.province = val.text
      this.selectedRegency = ''
      this.selectedDistrict = ''
      this.selectedSubdistrict = ''
      this.getRegency(val.value, this.input.address.regency)
    },
    selectedRegency: function(val) {
      this.input.address.regency = val.text
      this.selectedDistrict = ''
      this.selectedSubdistrict = ''
      this.getDistrict(val.value, this.input.address.district)
    },
    selectedDistrict: function(val) {
      this.input.address.district = val.text
      this.selectedSubdistrict = ''
      this.getSubdistrict(val.value, this.input.address.subdistrict)
    },
    selectedSubdistrict: function(val) {
      this.input.address.subdistrict = val.text
    }
  },
  async mounted() {
    this.getProvince()
    if (this.id_member != '') {
      this.title = 'Ubah Data Member'
      this.isNew = false
      await this.getMember()
    }
    this.input.id_company = this.$store.getters.userLoggedIn.company.id
    this.input.timestamps.created_by = this.$store.getters.userLoggedIn.id
    if (_.lowerCase(this.$store.getters.userLoggedIn.role) == 'administrator') {
      this.isEdit = false
      this.isAdmin = true
    }
  },
  methods: {
    addRegion(type, name) {
      if (!name) {
        let region = ''
        if (type == 0) region = 'Provinsi'
        if (type == 1) region = 'Kabupaten'
        if (type == 2) region = 'Kecamatan'
        if (type == 3) region = 'Kelurahan'
        return this.swalToast('error', `${region} baru tidak boleh kosong`)
      }
      let saveUri = ''
      if (type == 0) saveUri = '/region/province'
      if (type == 1) saveUri = '/region/regency'
      if (type == 2) saveUri = '/region/district'
      if (type == 3) saveUri = '/region/subdistrict'

      this.runApiCalls(() => {
        axios
          .post(Constant.apiUrl.concat(saveUri), {
            name,
            id_province: this.selectedProvince.value,
            id_regency: this.selectedRegency.value,
            id_district: this.selectedDistrict.value,
            id_subdistrict: this.selectedSubdistrict.value,
            isDeletable: true
          })
          .then(response => {
            if (response.status == 200) {
              this.swalToast('success', `${name} Berhasil Ditambah`)
              this.regionName = ''
              this.regionType = ''

              this.selectedProvince = ''
              this.selectedRegency = ''
              this.selectedDistrict = ''
              this.selectedSubdistrict = ''
              this.getProvince()
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menimpan wilayah'
              })
            }
          })
          .catch(error => {
            if (error?.response?.data?.pesan) return this.swalToast('warning', error?.response?.data?.pesan)
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: error?.response?.data?.pesan
            })
          })
      })
    },
    async getProvince() {
      this.resource.loadingProvince = true
      this.runApiCalls(() => {})
      await axios.get(Constant.apiUrl.concat('/region/province')).then(response => {
        const resData = response.data.data
        this.resource.province = resData.map(r => {
          return {
            text: r.name,
            value: r._id
          }
        })
        this.resource.loadingProvince = false
      })
    },
    async getRegency(id_province, name) {
      this.resource.loadingRegency = true
      await axios
        .get(
          Constant.apiUrl.concat('/region/regency?province=', id_province, '&search=', encodeURI(name || ''))
        )
        .then(response => {
          const resData = response.data.data
          this.resource.regency = resData.map(r => {
            return {
              text: r.name,
              value: r._id
            }
          })
          if (name)
            this.resource.regency.forEach(p => {
              if (p.text == this.input.address.regency) {
                this.selectedRegency = p
              }
            })
          this.resource.loadingRegency = false
        })
    },
    extractNIK() {
      if (!this.input.birth_date)
        if (this.input.nik && this.input.nik.length >= 12) {
          let date = this.input.nik.slice(6, 12)
          if (parseInt(date.slice(0, 2)) > 40) {
            date = (parseInt(date.slice(0, 2)) - 40).toString().concat(date.slice(2, 6))
            if (date.length == 5) date = '0'.concat(date)
          }

          let dateMoment = moment(date, 'DDMMYY').format()
          if (dateMoment !== 'Invalid date') {
            let diff = moment().diff(dateMoment, 'day')
            if (diff <= 0) dateMoment = moment(dateMoment).add(-100, 'year')
            this.input.birth_date = dateMoment
          }
        }
    },
    async getDistrict(id_regency, name) {
      this.resource.loadingDistrict = true
      await axios
        .get(
          Constant.apiUrl.concat('/region/district?regency=', id_regency, '&search=', encodeURI(name || ''))
        )
        .then(response => {
          const resData = response.data.data
          this.resource.district = resData.map(r => {
            return {
              text: r.name,
              value: r._id
            }
          })
          if (name)
            this.resource.district.forEach(p => {
              if (p.text == this.input.address.district) {
                this.selectedDistrict = p
              }
            })
          this.resource.loadingDistrict = false
        })
    },
    async getSubdistrict(id_district, name) {
      this.resource.loadingSubdistrict = true
      await axios
        .get(
          Constant.apiUrl.concat(
            '/region/subdistrict?district=',
            id_district,
            '&search=',
            encodeURI(name || '')
          )
        )
        .then(response => {
          const resData = response.data.data
          this.resource.subdistrict = resData.map(r => {
            return {
              text: r.name,
              value: r._id
            }
          })
          if (name)
            this.resource.subdistrict.forEach(p => {
              if (p.text == this.input.address.subdistrict) {
                this.selectedSubdistrict = p
              }
            })
          this.resource.loadingSubdistrict = false
        })
    },
    async getMember() {
      try {
        this.loading = true
        await this.runApiCalls(() => {
          axios.get(Constant.apiUrl.concat('/member/', this.id_member)).then(response => {
            const member = response.data.data
            this.input.id_company = _.get(member, 'id_company._id', '')
            this.input.nik = _.get(member, 'nik', '')
            this.input.name = _.get(member, 'name', '')
            this.input.birth_date = moment(_.get(member, 'birth_date', '')).format('YYYY-MM-DD')
            this.input.phone = _.get(member, 'phone', '')
            this.input.religion = _.get(member, 'religion', '')
            this.input.profession = _.get(member, 'profession', '')
            this.input.business = _.get(member, 'business', '')
            this.input.address = _.get(member, 'address', '')
            this.input.isActive = _.get(member, 'isActive', '')
            this.activeRadio = this.input.isActive ? 'yes' : 'no'
            this.resource.province.forEach(p => {
              if (p.text == this.input.address.province) {
                this.selectedProvince = p
              }
            })
            this.loading = false
          })
        })
      } catch (error) {
        this.loading = false
      }
    },

    saveMember() {
      try {
        if (!this.loadingSave) {
          this.loadingSave = true
          this.input.isActive = this.activeRadio == 'yes' ? true : false
          this.input.id_company = this.input.id_company || this.$store.getters.userLoggedIn.company.id

          if (!this.id_member) {
            axios
              .post(Constant.apiUrl.concat('/member/register'), this.input)
              .then(response => {
                if (response.status === 200) {
                  this.onCloseButtonClick()
                  Swal.fire('Berhasil', 'Data Anggota Berhasil Disimpan', 'success')
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
              .finally(() => {
                this.loadingSave = false
              })
          } else {
            axios
              .put(Constant.apiUrl.concat('/member/', this.id_member), this.input)
              .then(response => {
                if (response.status === 200) {
                  Swal.fire('Berhasil', 'Data Anggota Berhasil Perbarui', 'success')
                  this.loadingSave = false
                  this.onCloseButtonClick()
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
              .finally(() => {
                this.loadingSave = false
              })
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan anggota!',
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },

    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
  &__input-region {
    margin-left: 10px;
    max-width: 53%;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
