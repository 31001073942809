/* eslint-disable prettier/prettier */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueApexCharts from 'vue-apexcharts'
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib' //Globally import VTextField
import eventBus from './eventBus'

import '@mdi/font/css/materialdesignicons.css'

import './sass/main.scss'
import './sass/variables.scss'

Vue.prototype.$eventBus = eventBus

Vue.config.productionTip = false

Vue.use(VueApexCharts)

Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, {
  locale: 'id-ID',
  decimalLength: 0,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
