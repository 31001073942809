/* eslint-disable prettier/prettier */
const constant = {
  apiUrl: process.env.VUE_APP_API_URL,
  passwordAuth: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z!@#$%&?"_'-]{8,}$/,
  menus: [
    {
      title: 'Neraca',
      icon: 'mdi-scale-balance',
      route: '/accounting/balancesheet',
      slug: 'balancesheet',
      isActive: true,
      allow: ['administrator', 'manager', 'accounting']
    },
    {
      title: 'Laba Rugi',
      icon: 'mdi-chart-areaspline',
      route: '/accounting/profit-loss',
      slug: 'profit-loss',
      isActive: true,
      allow: ['administrator', 'manager', 'accounting']
    },
    {
      title: 'Buku Besar',
      icon: 'mdi-book-open',
      route: '/accounting/ledger',
      slug: 'ledger',
      isActive: true,
      allow: ['administrator', 'manager', 'accounting']
    },
    {
      title: 'Akun',
      icon: 'mdi-notebook',
      route: '/accounting/chartmain',
      slug: 'chartmain',
      isActive: true,
      allow: ['administrator', 'accounting']
    },
    {
      title: 'Jurnal',
      icon: 'mdi-notebook-edit-outline',
      route: '/accounting/journal',
      slug: 'journal',
      isActive: true,
      allow: ['administrator', 'accounting']
    },
    {
      title: 'Fee BMT',
      icon: 'mdi-cash-sync',
      route: '/accounting/sharing-fee',
      slug: 'sharing-fee',
      isActive: true,
      allow: ['administrator', 'accounting']
    },
    {
      title: 'Wilayah',
      icon: 'mdi-flag-outline',
      route: '/region',
      slug: 'region',
      isActive: true,
      allow: ['administrator', 'manager']
    },
    {
      title: 'BMT',
      icon: 'mdi-office-building-outline',
      route: '/company',
      slug: 'company',
      isActive: true,
      allow: ['administrator']
    },
    {
      title: 'Karyawan',
      icon: 'mdi-account-cog-outline',
      route: '/staff',
      slug: 'staff',
      isActive: true,
      allow: ['administrator']
    },
    {
      title: 'Produk',
      icon: 'mdi-file-document-multiple-outline',
      route: '/product',
      slug: 'product',
      isActive: true,
      allow: ['administrator', 'data input']
    },
    {
      title: 'Anggota',
      icon: 'mdi-account-multiple-plus-outline',
      route: '/member',
      slug: 'member',
      isActive: true,
      allow: ['administrator', 'data input', 'manager']
    },
    {
      title: 'Peserta',
      icon: 'mdi-account-cash-outline',
      slug: 'subscription',
      route: '/subscription/input',
      isActive: true,
      allow: ['administrator', 'data input']
    },
    {
      title: 'Pembayaran Iuran',
      icon: 'mdi-cash-check',
      slug: 'subscription',
      route: '/subscription/payment',
      isActive: true,
      allow: ['administrator', 'data input']
    },
    {
      title: 'Pengajuan Klaim',
      icon: 'mdi-account-heart-outline',
      slug: 'subscription',
      route: '/subscription/claim',
      isActive: true,
      allow: ['administrator']
    },
    {
      title: 'Riwayat Peserta',
      icon: 'mdi-account-convert',
      slug: 'subscription',
      route: '/subscription/history',
      isActive: true,
      allow: ['administrator', 'data input', 'manager']
    }
    // {
    //   title: 'Laporan',
    //   icon: 'mdi-chart-bell-curve-cumulative',
    //   slug: 'reporting',
    //   route: '/subscription/reporting',
    //   allow: ['administrator', 'data input', 'manager']
    // }
    // {
    //   title: 'Laporan',
    //   icon: 'mdi-chart-bell-curve',
    //   route: '/report',
    //   slug: 'report',
    //   allow: ['administrator', 'manager']
    // }
  ]
}

export default constant
