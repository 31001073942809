<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col cols="4">
                <div class="detail-input">
                  <v-text-field
                    id="name"
                    label="Pencarian Peserta"
                    v-model="table.search"
                    dense
                    placeholder="Cari Peserta . . ."
                    class="detail-input__input-text"
                  /></div
              ></v-col>
              <v-col cols="2"> </v-col>
              <v-col offset="2" cols="4">
                <div class="detail-input">
                  <v-currency-field
                    id="total"
                    readonly
                    label="Total Pembayaran"
                    v-model="total"
                    dense
                    prefix="Rp."
                    class="detail-input__input-text"
                  /></div
              ></v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :headers="table.headers"
                  :items="subscriptions"
                  :loading="loading"
                  :search="table.search"
                  class="elevation-0"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-menu bottom origin="center center" transition="scale-transition">
                      <template v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                              <v-icon dark>
                                mdi-menu
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>Menu</span>
                        </v-tooltip>
                      </template>
                      <v-list dense>
                        <v-subheader>{{ `${item.companyName} - ${item.memberName}` }}</v-subheader>
                        <v-list-item @click="removeSubscription(item)">
                          <v-list-item-icon>
                            <v-icon>
                              mdi-cash-remove
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Batal Pembayaran</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <br />
            <v-container>
              <v-row no-gutters>
                <template>
                  <v-row>
                    <v-col md="8" align-self="center">
                      <label for="member">
                        <h3>
                          <b>{{ templates.title }}</b>
                        </h3>
                      </label>
                    </v-col>
                    <v-col md="4" align-self="center" v-if="isSave">
                      <v-flex xs6>
                        <input
                          type="file"
                          id="files"
                          ref="files"
                          accept="image/png, image/jpeg, application/pdf"
                          v-on:change="handleFilesUpload()"
                          v-show="false"
                        />

                        <v-btn
                          v-if="!isAdmin && isUserHeadquarters == input.isHeadquarters"
                          depressed
                          block
                          class="upload-button mt-3 mb-2"
                          @click="addFiles()"
                          :loading="loadingUpload"
                        >
                          Pilih File</v-btn
                        >
                      </v-flex>
                    </v-col>
                  </v-row>
                  <br />
                  <v-row>
                    <template v-if="templates.failedFiles.length">
                      <v-col align-self="center" md="8">
                        <v-flex md9 class="mx-2">
                          <p class="text-md-left ma-0">
                            File {{ templates.failedFiles[0].file.name }} gagal diunggah karena
                            <span class="yellow--text ">{{ templates.failedFiles[0].message }}</span>
                          </p>
                        </v-flex>
                      </v-col>
                      <v-col md="4">
                        <v-flex md1>
                          <v-btn
                            :loading="loadingUpload"
                            small
                            block
                            color="error"
                            depressed
                            @click="removeWarning()"
                          >
                            Hapus
                          </v-btn>
                        </v-flex>
                      </v-col>
                    </template>
                  </v-row>
                  <v-row>
                    <template v-if="templates.files.length">
                      <v-col align-self="center" md="6">
                        <v-flex md7>
                          <p class="text-md-left">
                            {{ templates.files[0].name || templates.files[0].originalname }}
                          </p>
                        </v-flex>
                      </v-col>
                      <v-row align="center" md="6">
                        <v-col md="6">
                          <v-btn
                            :loading="loadingUpload"
                            small
                            block
                            color="primary"
                            :disabled="templates.files[0].isDelete"
                            depressed
                            @click="previewFile(templates.files[0])"
                          >
                            {{ templates.files[0].isDelete ? 'Akan diHapus' : 'Tampilkan' }}
                          </v-btn>
                        </v-col>
                        <v-col md="6" v-if="!isAdmin">
                          <v-btn
                            :loading="loadingUpload"
                            v-if="!templates.files[0].isExist"
                            small
                            block
                            color="error"
                            depressed
                            @click="removeFiles()"
                          >
                            Batal
                          </v-btn>
                          <v-btn
                            :loading="loadingUpload"
                            v-else
                            small
                            block
                            color="error"
                            depressed
                            @click="deleteFiles()"
                          >
                            {{ templates.files[0].isDelete ? 'Batal Hapus' : 'Hapus' }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </template>
                  </v-row>
                </template>
              </v-row>
              <br />
              <v-divider></v-divider>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <div class="mr-10">
        <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
          Kembali
        </v-btn>

        <v-btn v-if="isAdmin" color="primary" width="130" @click="savePayment()" large>
          Konfirmasi <br />
          pembayaran
        </v-btn>
        <!-- <v-btn
                      v-if="isSave && isConfirm && !isAdmin"
                      color="primary"
                      width="150"
                      @click="submitFiles()"
                      large
                    >
                      Upload <br />
                      Bukti Transfer
                    </v-btn> -->
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  mixins: [ConstantMixin],
  props: {
    id_payment: String,
    isSave: Boolean
  },
  data: () => ({
    title: 'Permintaan Pembayaran',
    loading: false,
    loadingSubs: false,
    loadingUpload: false,
    subscriptions: [],
    selectedSubs: [],
    dialog: false,
    dialogRemove: false,
    isNew: true,
    isConfirm: false,
    isUserHeadquarters: false,
    isAdmin: false,
    load: true,
    templates: {
      title: 'Bukti Transfer',
      isEnable: true,
      files: [],
      failedFiles: []
    },
    table: {
      search: '',
      headers: [
        // { text: 'id', value: 'id', width: '100px' },
        { text: 'Peserta', value: 'memberName', width: '100px' },
        { text: 'Cabang', value: 'companyName', width: '100px' },
        { text: 'Produk', value: 'productName', width: '150px' },
        { text: 'Pengajuan', value: 'requestPaidDate', width: '100px' },
        { text: 'Pencairan', value: 'disbursement', width: '100px' },
        { text: 'Tarif Iuran', value: 'tariffPercent', width: '100px' },
        { text: 'Jumlah Iuran', value: 'tariffNominal', width: '100px' }
      ],
      pagination: {
        descending: false,
        itemsPerPage: 10,
        sortBy: ''
      }
    },
    total: 0,
    input: {
      isDemo: false,
      isHeadquarters: false,
      batch: '',
      id_company: '',
      subscription: [],
      isConfirm: false,
      timestamps: {
        created_by: ''
      }
    }
  }),
  computed: {},
  watch: {},

  mounted() {
    if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
      this.isAdmin = true
      this.table.headers.push({ text: '', value: 'actions', width: '30px' })
    }

    if (this.id_payment != '') {
      this.title = 'Konfirmasi Pembayaran'
      this.isNew = false
      this.getPayment()
    } else {
      this.input.id_company = this.$store.getters.userLoggedIn.company.id
    }
    this.isUserHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
    if (!this.isUserHeadquarters) this.table.headers.splice(1, 1)
    this.input.isDemo = this.isDemo
  },
  methods: {
    removeSubscription(item) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: `Menghapus ${item.memberName} dari Daftar pembayaran`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          this.swalLoading()
          this.runApiCalls(() => {
            axios
              .put(Constant.apiUrl.concat('/payment/remove/', this.id_payment), {
                subscriptions: [item.id],
                id_staff: this.$store.getters.userLoggedIn.id
              })
              .then(response => {
                if (response.status === 200) {
                  setTimeout(() => {
                    this.total = 0
                    this.getPayment()
                    this.swalToast('success', 'Berhasil menghapus peserta')
                  }, 1000)
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
          })
        }
      })
    },
    getPayment() {
      try {
        this.loading = true
        axios.get(Constant.apiUrl.concat('/payment/', this.id_payment)).then(response => {
          const payment = response.data.data
          if (!_.get(payment, 'isHeadquarters', false)) this.table.headers.splice(1, 1)
          this.input.isHeadquarters = _.get(payment, 'isHeadquarters', false)
          this.input.batch = _.get(payment, 'batch', '')
          this.input.id_company = _.get(payment, 'id_company', '')
          this.input.periode = _.get(payment, 'periode', '')
          this.input.isConfirm = _.get(payment, 'isConfirm', false)
          this.input.subscription = _.get(payment, 'subscription', '')
          this.input.description = _.get(payment, 'description', '')
          this.subscriptions = _.sortBy(
            _.get(payment, 'subscription', '')
              .filter(
                v =>
                  v.companyName == this.$store.getters.userLoggedIn.company.branchName ||
                  this.isUserHeadquarters
              )
              .map(s => {
                this.total += s.tariffNominal

                return {
                  ...s,
                  tariffPercent: s.tariffPercent.toString().concat(' %'),
                  tariffNominal: this.getCurrency(s.tariffNominal),
                  requestPaidDate: moment(s.requestPaidDate)
                    .locale('id')
                    .format('DD MMM YYYY'),
                  disbursement: moment(s.disbursement)
                    .locale('id')
                    .format('DD MMM YYYY')
                }
              }),
            ['tariffNominal']
          )

          this.loading = false
          if (_.get(payment, 'bill_document')) this.templates.files[0] = _.get(payment, 'bill_document')
          else
            this.templates = {
              title: 'Bukti Transfer',
              isEnable: true,
              files: [],
              failedFiles: []
            }(this.templates)
          this.isConfirm = !this.input.isConfirm
        })
      } catch (error) {
        this.loading = false
      }
    },
    savePayment() {
      try {
        Swal.fire({
          title: 'Anda Yakin ?',
          text: `Pastikan sudah sesuai sebelum melanjutkan`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then(result => {
          if (result.value) {
            this.runApiCalls(() => {
              axios
                .put(
                  Constant.apiUrl.concat(
                    '/payment/',
                    this.id_payment,
                    '/',
                    this.$store.getters.userLoggedIn.id
                  )
                )
                .then(response => {
                  if (response.status === 200) {
                    this.onCloseButtonClick()
                    // this.$socket.emit('payment:update', {
                    //   staff: this.$store.getters.userLoggedIn,
                    //   company: this.input.id_company
                    // })
                    Swal.fire('Berhasil', 'Data Iuran Peserta Telah Dikonfirmasi', 'success')
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
            })
          }
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan payment!',
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files[0]
      if (!this.verify(filesUploaded)) {
        if (this.templates.files.length > 0) this.templates.files = []
        this.templates.files.push(filesUploaded)
        this.submitFiles()
      } else {
        if (this.templates.failedFiles.length > 0) this.templates.failedFiles = []
        this.templates.failedFiles.push({
          file: filesUploaded,
          message: this.verify(filesUploaded)
        })
      }
      this.$refs.files.value = ''
    },
    async submitFiles(isDelete) {
      this.loadingUpload = true
      const formData = new FormData()
      this.templates.files.forEach(file => {
        formData.append(`file`, file.isExist ? JSON.stringify(file) : file)
      })
      formData.append('id_payment', this.id_payment)

      await this.runApiCalls(async () => {
        await axios
          .post(Constant.apiUrl.concat('/payment/upload'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            if (response.status === 200) {
              this.loadingUpload = false
              this.getPayment()
              Swal.fire(
                'Berhasil!',
                isDelete ? 'Dokumen berhasil dihapus' : 'Dokumen berhasil diunggah',
                'success'
              )
            }
          })
          .catch(error => {
            this.loadingUpload = false
            this.templates.files.splice(0, 1)
            this.showErrorAxios(error)
          })
      })
    },
    addFiles() {
      this.$refs.files.click()
    },
    previewFile(file) {
      let url = ''
      if (typeof file === 'object') url = Constant.apiUrl.concat(`/${file.path}`)
      else url = URL.createObjectURL(file)
      window.open(url)
    },
    removeFiles() {
      this.templates.files.splice(0, 1)
    },
    async deleteFiles() {
      this.templates.files[0].isDelete = !this.templates.files[0].isDelete
      // this.getPayment()
      await this.submitFiles(true)

      this.templates.files.splice(0, 1)
    },
    removeWarning() {
      this.templates.failedFiles.splice(0, 1)
    },
    verify(file) {
      const maxSize = 10000000
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf']
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 10 MB'
      }

      const ext = file.name.split('.')

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-header {
  color: #222;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}

input:placeholder-shown {
  font-size: 0.5em;
}
</style>
