/* eslint-disable no-unused-vars */
<template>
  <div>
    <!-- <v-progress-linear
      v-if="loading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear> -->
    <transition name="dashboard">
      <div class="main-div">
        <!-- <h1>Data Pembayaran Peserta</h1> -->
        <div class="d-flex justify-space-around">
          <v-card min-height="76" min-width="950" class="white--text " elevation="2" color="blue">
            <v-card-text class="cardtext white--text">
              <v-row v-if="!loadingHeader && isAdmin" no-gutters class="d-flex justify-space-around">
                <v-col cols="3">
                  <label><h1>Keseluruhan</h1></label>
                </v-col>
                <v-col cols="3">
                  <label>
                    <h2>
                      <b>{{ allPayment }}</b>
                    </h2></label
                  >
                </v-col>
              </v-row>
              <v-row>
                <div class="my-2"></div>
              </v-row>
              <v-row v-if="!loadingHeader" no-gutters class="d-flex justify-space-around">
                <v-col cols="3">
                  <label><h1>Menunggu Konfirmasi</h1></label>
                </v-col>
                <v-col cols="3">
                  <label>
                    <h2>
                      <b>{{ waitingPayment }}</b>
                    </h2></label
                  >
                </v-col>
              </v-row>
              <v-row v-if="loadingHeader" no-gutters class="d-flex justify-space-around">
                <v-col cols="12" class="d-flex justify-center align-center">
                  <v-progress-circular :size="50" color="white" indeterminate></v-progress-circular>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </div>
        <div class="search">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-autocomplete
                v-if="isAdmin"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
                @change="
                  getAllPayment(false)
                  getAllPaymentHeader()
                "
              ></v-autocomplete>
              <v-autocomplete
                v-if="filter.selectedCompany && filter.branch.length"
                :items="filter.branch"
                v-model="filter.selectedBranch"
                class="px-5"
                label="Filter Cabang"
                item-value="value"
                item-text="text"
                single-line
                clearable
                @change="
                  getAllPayment(false)
                  getAllPaymentHeader()
                "
              ></v-autocomplete>

              <v-select
                :items="filter.list"
                v-model="filter.selectedPeriode"
                class="px-5"
                label="Filter Periode"
                item-text="name"
                item-value="value"
                single-line
                @change="
                  getAllPayment(false)
                  getAllPaymentHeader()
                "
              ></v-select>
            </v-card>
          </v-menu>
          <!-- <v-tooltip text="Tooltip">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" class="mr-5 ml-5" dark color="primary" @click="downloadExcelAllPayment()">
                <v-icon>mdi-table-arrow-down</v-icon>
              </v-btn>
            </template>
            <span>Export Excel Seluruh Pembayaran Bulan ini</span>
          </v-tooltip> -->
          <v-spacer></v-spacer>

          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllPayment()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
            @keyup="getAllPayment(true)"
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="payments"
          :loading="loading"
          :search="table.search"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          class="datatable pt-5 "
          height="380px"
          hide-default-footer
          item-key="id"
          dense
        >
          <template v-slot:[`item.isConfirm`]="{ item }">
            <div v-if="item.isConfirm">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green">
                    mdi-check-outline
                  </v-icon>
                </template>
                <span>Pembayaran Disetujui</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-timer-outline
                  </v-icon>
                </template>
                <span>Menunggu konfirmasi</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.isBillUploaded`]="{ item }">
            <div v-if="item.isBillUploaded">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" color="green" @click="previewFile(item.bill)">
                    mdi-check-outline
                  </v-icon>
                </template>
                <span>Nota Sudah Diupload</span>
              </v-tooltip>
            </div>
            <div v-else>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">
                    mdi-timer-outline
                  </v-icon>
                </template>
                <span>Menunggu Nota Diupload</span>
              </v-tooltip>
            </div>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-subheader>{{ `${item.companyName} - ${item.periode}` }}</v-subheader>
                <v-list-item @click="onClickFooter(0, item.id, item.id_company, item.isConfirm)">
                  <v-list-item-icon>
                    <v-icon>{{
                      isAdmin ? (item.isConfirm ? 'mdi-magnify' : 'mdi-cash-check') : 'mdi-magnify'
                    }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Lihat data Pembayaran</v-list-item-title>
                </v-list-item>
                <v-list-item
                  v-if="(isHeadquarters || isAdmin) && item.branchName == 'PUSAT'"
                  @click="onClickFooter(1, item.id, item.id_company, item.isConfirm)"
                >
                  <v-list-item-icon>
                    <v-icon>mdi-expand-all-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Detail Per-Cabang</v-list-item-title>
                </v-list-item>
                <v-list-item @click="exportToExcel(item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-microsoft-excel</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Export Excel</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin" @click="rejectSubscription(item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-close-thick</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Tolak Pembayaran</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="footer">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllPayment()"
            />
          </div>
          <v-spacer></v-spacer>
          <template>
            <v-dialog scrollable v-model="dialog" :max-width="selectedForm == 0 ? 1200 : 700">
              <payment
                :id_payment="selectedPayment.id_payment"
                :id_company="selectedPayment.id_company"
                :isSave="selectedPayment.isSave"
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
              <payment-group
                :id_payment="selectedPayment.id_payment"
                :id_company="selectedPayment.id_company"
                :isSave="selectedPayment.isSave"
                v-if="selectedForm == 1"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import Constant from '@/const'
import Payment from '../components/subscription/SubsPayment'
import PaymentGroup from '../components/subscription/SubsPaymentGrouped.vue'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

// const moment = require('moment')

export default {
  components: {
    Payment,
    PaymentGroup
  },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      loadingHeader: true,
      isAdmin: false,
      payments: [],
      paymentNewStatus: false,
      selectedPayment: '',
      dialog: '',
      selectedForm: null,
      waitingPayment: 0,
      allPayment: 0,
      pages: 0,
      filter: {
        selectedPeriode: '',
        periode: [],
        selectedCompany: '',
        company: [],
        selectedBranch: '',
        branch: []
      },
      table: {
        search: '',
        headers: [
          { text: 'Batch', value: 'batch', width: '100px', align: 'center', sortable: false },
          { text: 'Periode', value: 'periode', width: '100px', align: 'center', sortable: false },
          { text: 'Cabang', value: 'branchName', width: '100px', align: 'center', sortable: false },
          { text: 'Jumlah', value: 'total', width: '100px', align: 'end', sortable: false },
          { text: 'Status', value: 'isConfirm', width: '50px', align: 'center', sortable: false },
          { text: 'Nota', value: 'isBillUploaded', width: '50px', align: 'center', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 8,
          sortBy: ''
        }
      }
    }
  },
  created() {
    this.$socket.client.off('payment')
    this.$socket.client.on('payment', data => {
      this.getAllPayment(true)
    })
  },
  watch: {
    'table.search'(val) {
      this.table.pagination.page = 1
      setTimeout(this.getAllPayment(), 300)
    },
    'filter.selectedCompany': function(val) {
      if (val) this.getBranchCompany(val)
      else this.filter.branch = []
    },
    'filter.selectedPeriode': function(val) {
      if (!val) this.getAllPaymentHeader()
    },

    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllCompany()
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
        this.table.headers.unshift({ text: 'BMT', value: 'companyName', width: '100px' })
        this.isAdmin = true
      }

      this.isHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters

      this.filter.list = this.monthFilter()
      this.filter.selectedPeriode = this.filter.list[0].value
      this.getAllPaymentHeader()
      this.getAllPayment()
    })
  },
  methods: {
    getAllCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
          if (response.status == 200) {
            const companyDataGet = response.data.data
            this.filter.company = companyDataGet
              .filter(v => v.name !== 'ADMINISTRATOR' && v.isActive)
              .map(company => {
                return {
                  value: _.get(company, '_id', '').toString(),
                  text: _.get(company, 'name', '')
                }
              })
          }
          this.loadingCompany = false
        })
      })
    },
    getBranchCompany(id_company) {
      this.loadingBranch = true
      this.runApiCalls(() => {
        if (id_company) {
          axios.get(Constant.apiUrl.concat(`/master/company/branch/${id_company}`)).then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.filter.branch = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR' && v.isActive)
                .map(company => {
                  return {
                    value: _.get(company, '_id', '').toString(),
                    text: _.get(company, 'headquarters.description', '')
                  }
                })

              if (this.isAdmin) {
                this.filter.branch.unshift({ value: this.filter.selectedCompany, text: 'PUSAT' })
                this.filter.branch.unshift({ value: '', text: 'ALL' })
              } else {
                this.filter.branch.unshift({
                  value: this.$store.getters.userLoggedIn.company.id,
                  text: 'PUSAT'
                })
                this.filter.selectedBranch = this.$store.getters.userLoggedIn.company.id
              }
            }
            this.loadingBranch = false
          })
        } else {
          this.loadingBranch = false
          this.filter.selectedBranch = ''
          this.filter.branch = []
        }
      })
    },
    getAllPayment(isSocketTrigger = false) {
      this.loading = !isSocketTrigger
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany

      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/payment?page=',
              this.table.pagination.page,
              '&itemCount=',
              this.table.pagination.itemsPerPage,
              '&sort=',
              'isConfirm',
              '&search=',
              this.table.search,
              '&periode=',
              this.filter.selectedPeriode || '',
              '&company=',
              this.filter.selectedBranch ? this.filter.selectedBranch : company || ''
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data
              this.pages = 0
              this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
              this.payments = []

              this.payments = _.sortBy(
                data.map(payment => {
                  return {
                    id: payment?._id.toString(),
                    batch: payment.batch,
                    id_company: payment?.id_company?._id,
                    isHeadquarters: payment?.id_company?.headquarters?.description ? 'Cabang' : 'Pusat',
                    branchName: payment?.id_company?.headquarters?.description || 'PUSAT',
                    companyName: payment?.id_company?.name,
                    periode: (payment?.periode?.month || '').concat(' ', payment.periode.year || ''),
                    subscription: payment.subscription.length,
                    total: this.getCurrency(payment.total || 0),
                    isConfirm: payment.isConfirm,
                    isBillUploaded: payment.isBillUploaded,
                    bill: payment.bill_document,
                    description: payment.description
                  }
                }),
                ['isConfirm', 'periode', 'batch', 'total']
              )
            } else {
              this.pages = 0
              this.payments = []
            }
            this.loading = false
          })
      })
    },
    downloadExcelAllPayment() {
      try {
        this.isExcelLoading = true

        this.runApiCalls(() => {
          const fileLink = document.createElement('a')
          fileLink.href = Constant.apiUrl.concat(
            `/payment/xlsx-all?periode=`,
            this.filter.selectedPeriode || ''
          )
          fileLink.click()
          this.isExcelLoading = false
        })
      } catch (error) {
        this.isExcelLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    getAllPaymentHeader() {
      this.loadingHeader = true
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/payment/summary?periode=',
              this.filter.selectedPeriode || '',
              '&company=',
              this.filter.selectedBranch ? this.filter.selectedBranch : company || ''
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { payment, allPayment } = response.data.data

              this.waitingPayment = this.getCurrency(payment)
              this.allPayment = this.getCurrency(allPayment)
            } else {
              this.waitingPayment = this.getCurrency(0)
              this.allPayment = this.getCurrency(0)
            }
            this.loadingHeader = false
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: message
              })
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menyambung ke server'
              })
            } else {
              // eslint-disable-next-line
              console.log('Something happened in the middle of request')
            }
          })
      })
    },
    paidPayment(id_payment) {
      try {
        this.runApiCalls(() => {
          axios
            .put(Constant.apiUrl.concat('/payment/status/', id_payment, '?isPaid=true'), {})
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Status Iuran Berhasil dibayarkan', 'success')
                this.getAllPayment(false)
              }
            })
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    rejectSubscription(item) {
      Swal.fire({
        title: 'Anda Yakin Membatalkan Pembayaran ?',
        text: `Pembayaran dapat diulangi oleh bmt untuk membuat pembayaran ulang peserta`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          this.swalLoading()
          this.runApiCalls(() => {
            axios
              .put(Constant.apiUrl.concat('/payment/remove-all/', item), {
                id_staff: this.$store.getters.userLoggedIn.id
              })
              .then(response => {
                if (response.status === 200) {
                  setTimeout(() => {
                    this.getAllPayment()
                    this.getAllPaymentHeader()
                    this.swalToast('success', 'Berhasil Membatalkan Pembayaran')
                  }, 1000)
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
          })
        }
      })
    },
    exportToExcel(id_payment) {
      this.isExcelLoading = true
      try {
        this.runApiCalls(() => {
          const fileLink = document.createElement('a')
          fileLink.href = Constant.apiUrl.concat(
            `/payment/xlsx?`,
            `id_company=${this.$store.getters.userLoggedIn.company.id}`,
            `&id_payment=${id_payment}`
          )
          fileLink.click()
          this.isExcelLoading = false
        })
      } catch (error) {
        this.isExcelLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    },
    onClickFooter(index, id_payment, id_company, isConfirm) {
      this.selectedPayment = {
        id_payment,
        id_company,
        isSave: !isConfirm
      }
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },
    togglePropsExpanded(props) {
      this.$set(props, 'expanded', !props.expanded)
    },
    previewFile(file) {
      let url = ''
      if (typeof file === 'object') url = Constant.apiUrl.concat(`/${file.path}`)
      else url = URL.createObjectURL(file)
      window.open(url)
    },
    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllPayment()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}
.autocomplete {
  max-width: 17%;
  margin-right: 2%;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.cardtext {
  padding-top: 28px;
  text-align: start;
}
</style>
