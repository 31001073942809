var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"sidenav"},[_c('v-navigation-drawer',{attrs:{"mini-variant":_vm.isMini,"mini-variant-width":"75","color":"#30383a","dark":"","permanent":""},on:{"update:miniVariant":function($event){_vm.isMini=$event},"update:mini-variant":function($event){_vm.isMini=$event}},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list-item',{staticClass:"pt-2"},[_c('router-link',{attrs:{"tag":"div","to":"/"}},[(_vm.isMini)?_c('a',{staticClass:"anchor-tag"},[_c('img',{attrs:{"width":"55","src":require("@/assets/image/icontaawun.png"),"alt":"Taawun"},on:{"click":function($event){_vm.minify = true}}})]):_c('a',{staticClass:"anchor-tag"},[_c('img',{attrs:{"width":"180","src":require("@/assets/image/logotaawun.png"),"alt":"Taawun"},on:{"click":function($event){_vm.minify = false}}})])])],1),_c('v-container',{staticClass:"ma-0 pa-0 menus"},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.items),function(item){return _c('v-list-item',{key:item.title,staticClass:"d-flex justify-center py-2",class:{ white: item.isActive },attrs:{"to":item.route},on:{"click":function($event){{
              _vm.items.forEach(arr => {
                arr.isActive = arr === item
              })
            }}}},[_c('v-list-item-icon',{staticClass:"d-flex justify-center"},[_c('v-icon',{attrs:{"color":item.isActive ? 'blue lighten-1' : 'white',"large":item.isActive}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm-left",class:{
                'subtitle-1': item.isActive,
                'black--text': item.isActive
              }},[_vm._v(" "+_vm._s(item.title)+" ")]),_vm._l((item.children),function(child){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(item.isActive),expression:"item.isActive"}],key:child.title,class:{ grey: child.isActive },attrs:{"to":child.route},on:{"click":function($event){{
                  _vm.children.forEach(arr => {
                    arr.isActive = arr === child
                  })
                }}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-sm-left",class:{
                    'subtitle-2': item.isActive,
                    'black--text': item.isActive
                  }},[_vm._v(" "+_vm._s(child.title)+" ")])],1)],1)})],2)],1)}),1)],1)],1),(_vm.isMini)?_c('div',[_c('br')]):_vm._e(),_c('div',{staticClass:"tamtech_logo"},[(!_vm.isMini)?_c('label',{staticClass:"label"},[_vm._v("Powerd by :")]):_vm._e(),_c('a',{attrs:{"href":"https://tamtech.id","target":"_blank","rel":"noopener noreferrer"}},[(!_vm.isMini)?_c('v-img',{attrs:{"max-height":_vm.logoMaxHeigth,"max-width":_vm.logoMaxWidth,"src":require("@/assets/image/tamtech_full.png")}}):_c('v-img',{staticClass:"tamtech_logo_mini",attrs:{"max-height":_vm.logoMaxHeigth,"max-width":_vm.logoMaxWidth,"src":require("@/assets/image/tamtech_icon.png"),"position":"center center"}})],1)]),(!_vm.isMini)?_c('div',{staticClass:"d-flex justify-center white--text text-body-2"},[_vm._v(" Version "+_vm._s(_vm.version.fe)+" / "+_vm._s(_vm.version.be)+" ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }