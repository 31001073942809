<template>
  <v-container>
    <div class="">
      <v-row no-gutters>
        <v-col>
          <v-card class="white--text blue darken-3" elevation="2">
            <v-card-title>
              <label class="h3">Tahun Ini</label>
            </v-card-title>
            <v-card-text class="cardtext white--text">
              <v-row no-gutters>
                <v-col class="md-2">
                  <label>Anggota</label>
                </v-col>

                <v-col class="md-6">
                  <label>
                    <b>{{ topDashboard.member.year }}</b>
                  </label>
                  <!-- <v-progress-circular
                    v-else
                    :size="20"
                    indeterminate
                    color="primary"
                    v-model="isSubscriptionLoading"
                  ></v-progress-circular> -->
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="md-2">
                  <label>Peserta</label>
                </v-col>
                <v-col class="md-6">
                  <label>
                    <b>{{ topDashboard.subscription.year }}</b>
                  </label>
                </v-col>
              </v-row>
              <v-row no-gutters>
                <v-col class="md-2">
                  <label>Santunan</label>
                </v-col>
                <v-col class="md-6">
                  <label>
                    <b>{{ topDashboard.claim.year }}</b>
                  </label>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  name: 'YearlyData',
  components: {},
  mixins: { ConstantMixin },
  props: {
    dashboardData: Object
  },
  data() {
    return {
      topDashboard: {
        yearLable: '',
        claim: {
          year: 0
        },
        member: {
          year: 0
        },
        subscription: {
          year: 0
        }
      }
    }
  },
  watch: {},
  mounted() {
    this.topDashboard = this.dashboardData
  },
  methods: {}
}
</script>

<style lang="scss" scoped>
.graph-enter,
.graph-leave-to {
  opacity: 0;
}
.graph-enter-active,
.graph-leave-active {
  transition: all 1s ease;
}

.loading {
  width: 100%;
}
.chart {
  margin: 10px auto;
}
.chip {
  margin: 5px auto !important;
  max-width: 100%;
}
.dashboard {
  margin: 10px auto;
  width: 95%;
  h1 {
    text-align: left;
  }
  .btn {
    color: #fff;
    border-radius: 5px;
    text-transform: none;
    background: #bedbef !important;
    &.active {
      background: #3ca7ff !important;
    }
  }
  .emergency {
    background: linear-gradient(180deg, #ffd28e 0%, #ff8957 100%);
    border-radius: 5px;
    color: #fff;
    height: 160px;
    width: 90%;
    padding: 28px 20px 25px 15px;
    text-align: left;
  }
  .graph-container {
    background: #f0f9ff;
    border-radius: 25px;
    width: 100%;
    padding: 10px;
    .graph {
      position: relative;
      width: 100%;

      &.loading {
        padding: 80px;
      }
    }
  }
}

.diagram {
  background-color: #fff8e155;
}

.cardtext {
  margin-left: 20px;
  text-align: left;
  .row {
    margin-bottom: 10px;
  }
}
</style>
