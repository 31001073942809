<template>
  <div class="login-box">
    <img class="logo" src="@/assets/image/taawun.png" alt="Taawun" />
    <h1>Log in.</h1>
    <span class="opacity-7">
      Log in with data that you entered during registration
    </span>
    <v-form class="login-form">
      <div class="wrapper">
        <v-text-field
          label="Username"
          name="username"
          id="username"
          v-model="username"
          @keydown="onEnter"
          append-icon="mdi-account"
          :rules="[rules.required]"
          outline
        />
      </div>
      <div class="wrapper">
        <v-text-field
          id="password"
          outline
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="[rules.required, rules.min]"
          :type="showPassword ? 'text' : 'password'"
          label="Password"
          name="password"
          @click:append="showPassword = !showPassword"
          v-model="password"
          @keydown="onEnter"
        ></v-text-field>
      </div>
      <div class="wrapper">
        <button type="submit" class="button" @click.prevent="login()">
          L O G I N
        </button>
        <a href="#" class="opacity-7">{{ version.fe }} / {{ version.be }} </a>
      </div>
    </v-form>
  </div>
</template>

<script>
import Constant from '@/const'
import router from '@/router'
import ConstantMixin from '@/constantMixin'
import vue2Dropzone from 'vue2-dropzone'

const { version } = require('../../../package.json')

const axios = require('axios')
// const io = require('socket.io-client')

// eslint-disable-next-line no-unused-vars
// const socket = io(process.env.VUE_APP_API_URL, {
//   reconnectionDelayMax: 10000
// })

axios.defaults.headers.post['Content-Type'] = 'application/json'

export default {
  mixins: [ConstantMixin],
  components: {},
  name: 'LoginBox',
  data() {
    return {
      username: '',
      password: '',
      showPassword: false,
      rules: {
        required: value => !!value || 'Diperlukan.',
        min: v => v.length >= 8 || 'Minimal 8 karakter'
      },
      version: {
        fe: '0.0.0',
        be: '0.0.0'
      }
    }
  },
  mounted() {
    this.version.fe = version
    this.getVersion()
  },
  watch: {
    // username(newValue) {
    //   if (newValue.length >= 10) {
    //     this.getUnit();
    //   }
    // },
  },

  methods: {
    getVersion() {
      axios.get(Constant.apiUrl.concat('/ver')).then(response => {
        this.version.be = response.data
        this.$store.commit('apiVersion', response.data)
      })
    },
    checkUsernamePassword() {
      if (this.username === '') {
        document.getElementById('username').focus()
        return false
      }
      if (this.password === '') {
        document.getElementById('password').focus()
        return false
      }
      return true
    },
    onEnter(e) {
      if (e.keyCode === 13) {
        this.login()
        e.preventDefault()
      }
    },
    login() {
      if (this.checkUsernamePassword()) {
        axios
          .post(Constant.apiUrl.concat('/master/staff/login'), {
            username: this.username,
            password: this.password
          })
          .then(response => {
            if (response.status === 200) {
              this.$store.commit('saveToken', response.data.token)
              axios.defaults.headers.common.Authorization = `Bearer ${response.data.token}`
              axios.defaults.headers.common.Demo = response.data.tokenStaff.isDemo || ''
              router.replace('/')
            }
          })
          .catch(error => {
            this.showErrorAxios(error)
          })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.login-box {
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  font-size: 1.2rem;
  img {
    height: 2.7em;
    margin-bottom: 5em;
  }
  .logo {
    height: 100%;
    width: 100%;
  }
  h1 {
    font-size: 2.2rem;
    margin-bottom: 0.25em;
  }
  span {
    font-size: 0.9rem;
    margin-bottom: 1em;
  }

  .login-form {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
  }

  label {
    opacity: 0.7;
    font-size: 0.9rem;
    margin-bottom: 0.5em;
  }

  a {
    display: block;
    margin: 0 auto;
    text-decoration: none;
    font-size: 1rem;
  }

  .button {
    font-size: 1rem;
    border-radius: 0.5em;
    border-style: solid;
    margin-bottom: 1em;
    padding: 1em;
    margin-top: 1em;
    background: #1f8ce5;
    color: #fff;
    font-weight: bolder;
  }

  .wrapper {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    width: 27em;
    align-content: center;
  }

  .opacity-4 {
    opacity: 0.4;
  }
  .opacity-7 {
    opacity: 0.7;
  }
}

// .login-box {
//   position: absolute;
//   font-family: 'Mont Extra Light', Helvetica, sans-serif;
//   right: 5vw;
//   bottom: 0;
//   background-image: url('~@/assets/image/loginbox-background.png');
//   background-repeat: no-repeat;
//   background-size: cover;
//   padding: 100px;
// }
// .login-form {
//   display: flex;
//   flex-direction: column;
//   text-align: left;
//   margin-top: 100px;
//   width: fit-content;
// }
// .input-label {
//   color: $primary-color;
//   font-weight: 700;
//   font-size: 1.25rem;
// }
// .input-text {
//   border-width: 0;
//   border-radius: 5%;
//   min-width: 300px;
//   max-width: 300px;
//   padding-left: 10px;
//   font-size: 1.25rem;
// }
// .input-text:focus {
//   outline: none;
// }
// .form-input {
//   display: flex;
//   flex-direction: row;
//   border-bottom: 2px solid $primary-color;
//   background-color: white;
//   margin-bottom: 15px;
//   align-items: center;
// }
// .form-input:focus-within {
//   border-bottom: 2px solid $darker-color;
// }
// .fa-icon {
//   color: $primary-color;
// }
// .link-forgot-password {
//   margin-top: -3px;
//   align-self: flex-end;
//   color: $primary-color;
//   text-decoration: none;
//   font-size: 13px;
//   font-weight: 700;
//   margin-bottom: 20px;
// }
// .btn-login {
//   background-color: $primary-color;
//   color: white;
//   border-radius: 10px;
//   border: none;
//   min-height: 35px;
// }
// .btn-login:hover {
//   background-color: $darker-color;
//   cursor: pointer;
// }

// @media only screen and (max-width: 425px) {
//   .login-box {
//     padding: 20px;
//     font-size: 0.75rem;
//     max-width: 350px;
//     position: absolute;
//     left: 56vw;
//     right: auto;
//     margin-left: -175px;
//     background-size: 100% 100%;
//   }
//   .login-form {
//     margin-bottom: 100px;
//     margin-right: auto;
//     margin-left: auto;
//   }
// }
</style>
