<template>
  <div>
    <v-progress-linear
      v-if="loadingSubDistrict"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear>
    <transition name="dashboard">
      <div v-if="!loadingSubDistrict" class="main-div">
        <h1>{{ mainTitle }}</h1>
        <div class="search">
          <v-autocomplete
            :items="table.category"
            v-model="category"
            class="autocomplete"
            label="Pilih Kategori Wilayah"
            single-line
            hide-details
          ></v-autocomplete>
          <v-btn v-if="category != -1" class="mx-2" @click="dialogOpenClose" color="primary">
            Tambah {{ categoryName }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="category >= 0"
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getRefresh()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <v-data-table
          v-if="category == 0"
          id="province"
          :headers="table.headerProvince"
          :items="province"
          :loading="loadingProvince"
          :footer-props="{ itemsPerPageOptions: table.itemsPerPage }"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="dialogOpenClose(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Ubah data Provinsi</span>
            </v-tooltip>
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="deleteRegion(item)">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Hapus data Provinsi</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-data-table
          v-if="category == 1"
          id="regency"
          :headers="table.headerRegency"
          :items="regency"
          :loading="loadingRegency"
          :footer-props="{ itemsPerPageOptions: table.itemsPerPage }"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="dialogOpenClose(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Ubah data Kabupaten</span>
            </v-tooltip>
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="deleteRegion(item)">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Hapus data Kabupaten</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-data-table
          v-if="category == 2"
          id="district"
          :headers="table.headerDistrict"
          :items="district"
          :loading="loadingDistrict"
          :footer-props="{ itemsPerPageOptions: table.itemsPerPage }"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="green">
              mdi-check-outline
            </v-icon>
            <v-icon v-else color="red">
              mdi-close-outline
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="dialogOpenClose(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Ubah data Kecamatan</span>
            </v-tooltip>
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="deleteRegion(item)">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Hapus data Kecamatan</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-data-table
          v-if="category == 3"
          id="subDistrict"
          :headers="table.headerSubDistrict"
          :items="subDistrict"
          :loading="loadingSubDistrict"
          :footer-props="{ itemsPerPageOptions: table.itemsPerPage }"
          class="elevation-0"
          hide-default-footer
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="dialogOpenClose(item)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <span>Ubah data Kelurahan</span>
            </v-tooltip>
            <v-tooltip v-if="item.isDeletable" bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon @click="deleteRegion(item)">
                    mdi-close
                  </v-icon>
                </v-btn>
              </template>
              <span>Hapus data Kelurahan</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <div class="footer">
          <div v-if="category >= 0" class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getRefresh()"
            />
          </div>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="300px">
              <v-card>
                <v-card-title>
                  <span class="headline">{{ categoryName }} Baru</span>
                </v-card-title>

                <v-container>
                  <v-row align-content="center" justify="center">
                    <v-col cols="12" class=" justify-center">
                      <div id="newProvince">
                        <v-autocomplete
                          v-if="category == 1"
                          :items="province"
                          :item-text="item => `${item.name}`"
                          v-model="input.parent"
                          class="padding"
                          label="Provinsi"
                          placeholder="Pilih Provinsi"
                          single-line
                          hide-details
                          return-object
                        />

                        <v-autocomplete
                          v-if="category == 2"
                          :items="regency"
                          :item-text="item => `${item.name} (${item.province})`"
                          v-model="input.parent"
                          class="padding"
                          label="Kabupaten"
                          placeholder="Pilih Kabupaten"
                          single-line
                          hide-details
                          return-object
                        /><v-autocomplete
                          v-if="category == 3"
                          :items="district"
                          :item-text="item => `${item.name} (${item.regency})`"
                          v-model="input.parent"
                          class="padding"
                          label="Kecamatan"
                          placeholder="Pilih Kecamatan"
                          single-line
                          hide-details
                          return-object
                        />
                        <br />
                        <v-text-field
                          v-model="input.name"
                          class="padding"
                          dense
                          label="Nama"
                          placeholder=""
                        />
                        <v-text-field
                          v-model="input.code"
                          :prefix="input.parent.code"
                          class="padding"
                          dense
                          label="Kode"
                          placeholder="0"
                          @keypress="onlyNumber"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" @click="dialogOpenClose" text>
                    Batal
                  </v-btn>
                  <v-btn color="primary" @click="saveNewRegion" text>Simpan</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

// eslint-disable-next-line no-unused-vars
const _ = require('lodash')
const axios = require('axios')

export default {
  components: {},
  mixins: [ConstantMixin],
  data() {
    return {
      mainTitle: 'Master Data Wilayah',
      loadingProvince: false,
      loadingRegency: false,
      loadingDistrict: false,
      loadingSubDistrict: false,
      isAdmin: false,
      province: [],
      regency: [],
      district: [],
      subDistrict: [],
      regionNewStatus: false,
      selectedRegion: '',
      dialog: '',
      selectedForm: null,
      pages: 0,
      search: '',
      categoryParent: -1,
      category: -1,
      categoryName: '',
      uri: '',
      input: {
        name: '',
        code: '',
        parent: ''
      },
      table: {
        category: [
          { text: 'Provinsi', value: 0 },
          { text: 'Kabupaten', value: 1 },
          { text: 'Kecamatan', value: 2 },
          { text: 'Kelurahan', value: 3 }
        ],
        search: '',
        headerProvince: [
          { text: 'Nama', value: 'name', width: '120px', sortable: false },
          { text: 'Kode', value: 'code', width: '120px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        headerRegency: [
          { text: 'Kabupaten', value: 'name', width: '120px', sortable: false },
          { text: 'Kode', value: 'code', width: '120px', sortable: false },
          { text: 'Provinsi', value: 'province', width: '120px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        headerDistrict: [
          { text: 'Kecamatan', value: 'name', width: '120px', sortable: false },
          { text: 'Kode', value: 'code', width: '120px', sortable: false },
          { text: 'Kabupaten', value: 'regency', width: '120px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        headerSubDistrict: [
          { text: 'Kelurahan', value: 'name', width: '120px', sortable: false },
          { text: 'Kode', value: 'code', width: '120px', sortable: false },
          { text: 'Kecamatan', value: 'district', width: '120px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        itemsPerPage: [5, 10, 15, 20],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      }
    }
  },
  watch: {
    category(value) {
      if (value == 0) this.categoryName = 'Provinsi'
      else if (value == 1) this.categoryName = 'Kabupaten'
      else if (value == 2) this.categoryName = 'Kecamatan'
      else if (value == 3) this.categoryName = 'Kelurahan'

      this.categoryParent = value - 1

      this.pages = 0
      this.table.pagination.page = 1
      this.getProvince()
      this.getRegency()
      this.getDistrict()
      this.getSubDistrict()
    },
    'input.parent'() {
      this.searchData(this)
    },
    'table.search'() {
      this.searchData(this)
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (this.$store.getters.userLoggedIn.role.role === 'administrator') this.isAdmin = true
    })
  },
  methods: {
    searchData: _.debounce(v => {
      v.getProvince()
      v.getRegency()
      v.getDistrict()
      v.getSubDistrict()
    }, 500),

    dialogOpenClose(item) {
      if (item) {
        this.input.name = item.name
        this.input.code = item.code
      }
      this.dialog = !this.dialog
      //* Get Data for Autocomplete
      if (this.category == 1) this.getProvince()
      if (this.category == 2) this.getRegency()
      if (this.category == 3) this.getDistrict()
    },
    saveNewRegion() {
      this.dialog = false
      let saveUri = ''
      if (this.category == 0) saveUri = '/region/province'
      if (this.category == 1) saveUri = '/region/regency'
      if (this.category == 2) saveUri = '/region/district'
      if (this.category == 3) saveUri = '/region/subdistrict'
      this.runApiCalls(() => {
        axios
          .post(Constant.apiUrl.concat(saveUri), {
            _id: `${this.input.parent.code || ''}${this.input.code || ''}`.trim(),
            name: this.input.name,

            id_province: this.category == 1 ? this.input.parent.code : undefined,
            id_regency: this.category == 2 ? this.input.parent.code : undefined,
            id_district: this.category == 3 ? this.input.parent.code : undefined,
            isDeletable: true
          })
          .then(response => {
            if (response.status == 200) {
              this.getRefresh()
              Swal.fire('Berhasil', `${this.categoryName} Berhasil Ditambah`, 'success')
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menimpan wilayah'
              })
            }
            this.loadingProvince = false
          })
          .catch(error => {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: error.response.data.pesan
            })
          })
      })
      this.input = {
        code: '',
        name: '',
        parent: ''
      }
    },
    getRefresh() {
      if (this.category == 0) this.getProvince()
      if (this.category == 1) this.getRegency()
      if (this.category == 2) this.getDistrict()
      if (this.category == 3) this.getSubDistrict()
    },
    getProvince() {
      if (this.category == 0 || this.dialog) {
        this.uri = '/region/province'

        this.loadingProvince = !this.dialog
        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                this.uri,
                '?search=',
                this.table.search == '' ? this.search : this.table.search,
                '&page=',
                this.dialog ? '' : this.table.pagination.page,
                '&itemCount=',
                this.dialog ? '' : this.table.pagination.itemsPerPage
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
                this.province = data.map(data => {
                  return {
                    code: data._id,
                    name: data.name,
                    isDeletable: data.isDeletable
                  }
                })
              }
              this.loadingProvince = false
            })
        })
      } else this.province = []
    },
    getRegency() {
      if (this.category == 1 || this.categoryParent == 0 || this.dialog) {
        this.uri = '/region/regency'
        this.loadingRegency = !this.dialog

        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                this.uri,
                '?search=',
                this.table.search == '' ? this.search : this.table.search,
                '&page=',
                this.dialog ? '' : this.table.pagination.page,
                '&itemCount=',
                this.dialog ? '' : this.table.pagination.itemsPerPage
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
                this.regency = data.map(data => {
                  return {
                    province: `${data.province} (${data.id_province})`,
                    code: data._id,
                    name: data.name,
                    isDeletable: data.isDeletable
                  }
                })
              }
              this.loadingRegency = false
            })
        })
      } else this.regency = []
    },
    getDistrict() {
      if (this.category == 2 || this.categoryParent == 1 || this.dialog) {
        this.uri = '/region/district'
        this.loadingDistrict = !this.dialog
        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                this.uri,
                '?search=',
                this.table.search == '' ? this.search : this.table.search,
                '&page=',
                this.dialog ? '' : this.table.pagination.page,
                '&itemCount=',
                this.dialog ? '' : this.table.pagination.itemsPerPage
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
                this.district = data.map(data => {
                  return {
                    regency: `${data.regency} (${data.id_regency})`,
                    code: data._id,
                    name: data.name,
                    isDeletable: data.isDeletable
                  }
                })
              }
              this.loadingDistrict = false
            })
        })
      } else this.district = []
    },
    getSubDistrict() {
      if (this.category == 3 || this.categoryParent == 2 || this.dialog) {
        this.uri = '/region/subdistrict'
        this.loadingSubDistrict = !this.dialog
        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                this.uri,
                '?search=',
                this.table.search == '' ? this.search : this.table.search,
                '&page=',
                this.dialog ? '' : this.table.pagination.page,
                '&itemCount=',
                this.dialog ? '' : this.table.pagination.itemsPerPage
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
                this.subDistrict = data.map(data => {
                  return {
                    district: `${data.district} (${data.id_district})`,
                    code: data._id,
                    name: data.name,
                    isDeletable: data.isDeletable
                  }
                })
              }
              this.loadingSubDistrict = false
            })
        })
      } else this.subDistrict = []
    },
    deleteRegion(items) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: `Data ${this.categoryName} ${items.name}(${items.code}) akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          axios
            .post(Constant.apiUrl.concat(`${this.uri}/${items.code}`))
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Wilayah Berhasil Dihapus', 'success')
                this.getRefresh()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}

.autocomplete {
  max-width: 20%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
