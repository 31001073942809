<template>
  <div class="mt-n10 mx-n3">
    <v-progress-linear
      v-if="isSubscriptionLoading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear>
    <transition name="graph">
      <v-container>
        <br />
        <v-row class="d-flex justify-space-around">
          <v-col cols="3">
            <monthly-data :dashboardData="topDashboard"></monthly-data>
          </v-col>

          <v-col cols="3">
            <yearly-data :dashboardData="topDashboard"></yearly-data>
          </v-col>
        </v-row>

        <v-col cols="10" offset="1" class="elevation-3">
          <v-row>
            <v-col class="d-flex justify-center">
              <h3 class="my-3">Grafik Jumlah Anggota, Peserta dan Santunan</h3>
            </v-col>
          </v-row>

          <v-row>
            <chart-line
              class="chart"
              v-if="isSubscriptionLoaded"
              :chartData="chartData"
              :options="options"
            ></chart-line>

            <v-col v-if="!isSubscriptionLoaded" class="d-flex justify-center">
              <v-progress-circular
                class="my-10"
                :size="200"
                :width="9"
                indeterminate
                color="primary"
                v-model="isSubscriptionLoading"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-container>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'
// import ChartBar from './chart/ChartBar.vue'
import ChartLine from './chart/ChartLine.vue'
import MonthlyData from './MonthlyData.vue'
import YearlyData from './YearlyData.vue'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  name: 'DashboardPage',
  components: { ChartLine, MonthlyData, YearlyData },
  mixins: { ConstantMixin },
  data() {
    return {
      isRefreshing: false,
      isSubscriptionLoading: true,
      isSubscriptionLoaded: false,
      isRegionLoading: false,
      company: '',
      topDashboard: {
        monthLable: '',
        yearLable: '',
        claim: {
          month: 0,
          year: 0
        },
        member: {
          month: 0,
          year: 0
        },
        subscription: {
          month: 0,
          year: 0
        }
      },
      chartData: {
        labels: [],
        datasets: [
          {
            label: '',
            backgroundColor: '#f87979',
            borderColor: '#f87979',
            fill: false,
            data: []
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        responsive: true,
        maintainAspectRatio: false,
        hover: {
          mode: 'nearest',
          intersect: true
        },
        tooltips: {
          mode: 'index',
          intersect: false
        },
        display: 'auto',
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: false
              }
            }
          ]
        }
      }
    }
  },
  mounted() {
    this.topDashboard.monthLable = `Bulan ${moment()
      .locale('id')
      .format('MMMM')} `
    this.topDashboard.yearLable = `Tahun ${moment()
      .locale('id')
      .format('YYYY')} `
    this.isHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
    this.company = this.$store.getters.userLoggedIn.company.id
    if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') this.isAdmin = true

    this.getReportSubscription()
  },
  methods: {
    getReportSubscription() {
      try {
        this.isSubscriptionLoading = true
        this.isSubscriptionLoaded = false

        axios
          .get(
            Constant.apiUrl.concat(
              '/reporting/dashboard/subscription?',
              'company=',
              this.isAdmin ? '' : this.company
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { labels, datasets, legends } = response.data.data

              this.topDashboard.member.year = _.sum(datasets[0].data)
              this.topDashboard.subscription.year = _.sum(datasets[1].data)
              this.topDashboard.claim.year = _.sum(datasets[2].data)

              const month = parseInt(moment().format('M')) - 1
              this.topDashboard.member.month = datasets[0].data[month]
              this.topDashboard.subscription.month = datasets[1].data[month]
              this.topDashboard.claim.month = datasets[2].data[month]

              this.chartData.labels = labels
              this.chartData.datasets = datasets
              this.chartData.legends = legends

              this.isSubscriptionLoaded = true
            }
            this.isSubscriptionLoading = false
          })
      } catch (error) {
        this.isSubscriptionLoading = false
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: error
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.graph-enter,
.graph-leave-to {
  opacity: 0;
}
.graph-enter-active,
.graph-leave-active {
  transition: all 1s ease;
}

.loading {
  width: 100%;
}
.chart {
  width: 90%;
  margin: 10px auto;
}
.chip {
  margin: 5px auto !important;
  max-width: 100%;
}
.dashboard {
  margin: 10px auto;
  width: 95%;
  h1 {
    text-align: left;
  }
  .btn {
    color: #fff;
    border-radius: 5px;
    text-transform: none;
    background: #bedbef !important;
    &.active {
      background: #3ca7ff !important;
    }
  }
  .emergency {
    background: linear-gradient(180deg, #ffd28e 0%, #ff8957 100%);
    border-radius: 5px;
    color: #fff;
    height: 160px;
    width: 90%;
    padding: 28px 20px 25px 15px;
    text-align: left;
  }
  .graph-container {
    background: #f0f9ff;
    border-radius: 25px;
    width: 100%;
    padding: 10px;
    .graph {
      position: relative;
      width: 100%;

      &.loading {
        padding: 80px;
      }
    }
  }
}

.diagram {
  background-color: #fff8e155;
}

.cardtext {
  margin-left: 20px;
  text-align: left;
  .row {
    margin-bottom: 10px;
  }
}
</style>
