const jspdf = require('jspdf')
const qrcodeBase64 = require('qrcode-base64')
const moment = require('moment')
const momentTz = require('moment-timezone')
const _ = require('lodash')
const axios = require('axios')
const Constant = require('@/const')
const ConstantMixin = require('@/constantMixin')
import loadImage from 'async-image'

export default async function createCertificate({
  staff,
  company,
  id_subscription,
  member,
  isClaim = false
}) {
  const { data } = await axios.get(Constant.default.apiUrl.concat('/subscription/', id_subscription))
  const dataSubs = data.data
  const qrcode = qrcodeBase64.drawImg(
    `Sertifikat ini dibuat oleh ${staff} dari ${company} pada tanggal ${moment()
      .locale('id')
      .format('DD MMMM YYYY')} jam ${moment()
      .locale('id')
      .format('HH:MM:SS')}`,
    { typeNumber: 4, errorCorrectLevel: 'M', size: 500 }
  )

  let imageLogo = await loadImage(Constant.default.apiUrl.concat(`/static/storage/assets/logotaawun.png`))
  let imageSignature = await loadImage(Constant.default.apiUrl.concat(`/static/storage/assets/signature.png`))

  const doc = new jspdf.jsPDF()
  doc.setFontSize(12)
  doc.addImage(imageLogo, 'png', 10, 10, 70, 18)

  let certNumber = dataSubs.subscription_number.split('/')
  certNumber.shift()
  certNumber = certNumber.join('/')
  doc.text(`SERTIFIKAT ${isClaim ? 'SANTUNAN' : ''}`, 140, 25)

  let labels = []
  if (isClaim) {
    const requestClaimDate = dataSubs.claim_status?.find(v => v.status == 'requested')?.created_at

    labels = [
      { text: 'BMT', value: dataSubs.company.name },
      { text: 'Produk', value: dataSubs.product.name },
      { text: 'Nomor Sertifikat', value: certNumber },
      {
        text: 'Masa Kepesertaan',
        value: `${dataSubs.periode} Bulan (${moment(dataSubs.disbursement.substring(0, 10))
          .locale('id')
          .format('DD MMMM YYYY')} - ${moment(dataSubs.due_date.substring(0, 10))
          .locale('id')
          .format('DD MMMM YYYY')})`
      },
      { text: 'Nama Anggota', value: dataSubs.member.name },
      {
        text: 'Tanggal Lahir',
        value: moment(dataSubs.member.birth_date)
          .locale('id')
          .format('DD MMMM YYYY')
      },
      {
        text: 'Tanggal Kematian',
        value: dataSubs?.passed_date
          ? moment(dataSubs.passed_date)
              .locale('id')
              .format('DD MMMM YYYY')
          : ''
      },
      {
        text: 'Pembiayaan Awal',
        value: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
          dataSubs.plafond || 0
        )
      },
      {
        text: 'Iuran Taawun',
        value: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
          dataSubs.tariff.nominal || 0
        )
      },
      {
        text: 'Permohonan Santunan',
        value: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
          dataSubs.claim || 0
        )
      },
      {
        text: 'Tanggal Masuk Santunan',
        value: requestClaimDate
          ? `${moment(requestClaimDate.substring(0, 10))
              .locale('id')
              .format('DD MMMM YYYY')}`
          : ''
      }
    ]
  } else {
    labels = [
      { text: 'BMT', value: dataSubs.company.name },
      { text: 'Produk', value: dataSubs.product.name },
      { text: 'Nama Anggota', value: dataSubs.member.name },
      {
        text: 'Tanggal Lahir',
        value: moment(dataSubs.member.birth_date)
          .locale('id')
          .format('DD MMMM YYYY')
      },
      { text: 'Nomor Sertifikat', value: certNumber },
      {
        text: 'Masa Kepesertaan',
        value: `${dataSubs.periode} Bulan (${moment(dataSubs.disbursement.substring(0, 10))
          .locale('id')
          .format('DD MMMM YYYY')} - ${moment(dataSubs.due_date.substring(0, 10))
          .locale('id')
          .format('DD MMMM YYYY')})`
      },
      {
        text: 'Iuran Taawun',
        value: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
          dataSubs.tariff.nominal || 0
        )
      },
      {
        text: 'Pembiayaan Awal',
        value: new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(
          dataSubs.plafond || 0
        )
      }
    ]
  }

  let labelPosition = 40
  const space = 8
  for (const label of labels) {
    doc.text(label.text, 30, labelPosition)
    doc.text(':', 80, labelPosition)
    doc.text(label.value, 90, labelPosition)
    labelPosition += space
  }
  doc.text('Sertifikat ini tunduk pada ketentuan Taawun yang berlaku', 20, labelPosition + 10)
  doc.text(
    `Yogyakarta, ${moment()
      .locale('id')
      .format('DD MMMM YYYY')}`,
    120,
    labelPosition + 30
  )
  doc.text(`PBMT Taawun`, 130, labelPosition + 38)
  doc.addImage(qrcode, 'png', 30, labelPosition + 25, 50, 50)
  doc.addImage(imageSignature, 'png', 110, labelPosition + 40, 65, 25)
  doc.text(`Ida Widiahastuti`, 130, labelPosition + 75)
  doc.text(`Direktur`, 138, labelPosition + 80)
  doc.save(
    `sertifikat_klaim_${dataSubs.member.name
      .toLowerCase()
      .split(' ')
      .join('_')}.pdf`
  )
}
