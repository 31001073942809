<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col md="10" offset-md="1">
            <v-row>
              <v-col md="5">
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="company">BMT</label>
                  </div>
                  <v-autocomplete
                    id="company"
                    v-model="selectedCompany"
                    dense
                    :readonly="!isNew"
                    required
                    return-object
                    :loading="loadingCompany"
                    :clearable="isNew"
                    placeholder="Pilih BMT"
                    class="detail-input__input-text"
                    :items="resource.company"
                  ></v-autocomplete>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="name">Nama Lengkap</label>
                  </div>
                  <v-text-field
                    id="name"
                    v-model="input.detail.name"
                    dense
                    placeholder="Masukkan Nama Lengkap"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="nik">NIK</label>
                  </div>
                  <v-text-field
                    id="nik"
                    @keypress="onlyNumber"
                    v-model="input.detail.nik"
                    dense
                    placeholder="Masukkan Nomor KTP"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="email">E-Mail</label>
                  </div>
                  <v-text-field
                    id="email"
                    v-model="input.detail.email"
                    dense
                    placeholder="Masukkan E-Mail"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="phone">No Telepon</label>
                  </div>
                  <v-text-field
                    id="phone"
                    @keypress="onlyNumber"
                    v-model="input.detail.phone"
                    dense
                    placeholder="Masukkan No Telepon"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
              </v-col>
              <v-col md="2"></v-col>
              <v-col md="5"
                ><div class="detail-input">
                  <div class="detail-input__label">
                    <label for="username">Akun</label>
                  </div>
                  <v-text-field
                    id="username"
                    v-model="input.username"
                    dense
                    placeholder="Masukkan nama untuk login"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input" v-if="isNew">
                  <div class="detail-input__label">
                    <label for="password">Kata sandi</label>
                  </div>
                  <v-text-field
                    placeholder="Masukkan Kata sandi"
                    id="password"
                    class="detail-input__input-text"
                    v-model="input.password"
                    :append-icon="showPassword ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword = !showPassword"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="role">Role</label>
                  </div>
                  <v-select
                    id="role"
                    v-model="input.detail.role"
                    dense
                    placeholder="Pilih Role"
                    class="detail-input__input-text"
                    :items="resource.role"
                    :loading="loading"
                    item-text="text"
                    item-value="value"
                  >
                  </v-select>
                </div>
                <div class="detail-input">
                  <div class="detail-input__label">
                    <label for="description">Keterangan</label>
                  </div>

                  <v-text-field
                    id="description"
                    v-model="input.detail.description"
                    dense
                    placeholder="Masukkan Detail"
                    class="detail-input__input-text"
                    required
                    :loading="loading"
                  />
                </div>
                <div class="detail-radio">
                  <div class="detail-input__label">
                    <label for="status">Status</label>
                  </div>
                  <v-radio-group id="status" dense v-model="activeRadio" row>
                    <v-radio label="Aktif" value="yes"></v-radio>
                    <v-radio label="Tidak Aktif" value="no"></v-radio>
                  </v-radio-group>
                </div>
              </v-col>
              <v-col cols="12">
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
                    Kembali
                  </v-btn>
                  <v-btn color="primary" width="100" :loading="loadingSave" @click="saveStaff()" large>
                    Simpan
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  mixins: [ConstantMixin],
  props: {
    id_staff: String
  },
  data: () => ({
    title: 'Daftar Staff Baru',
    loading: false,
    loadingCompany: true,
    loadingSave: false,
    dialog: false,
    dialogDelete: false,
    showPassword: false,
    activeRadio: '',
    isNew: true,
    companyName: '',
    selectedCompany: {},
    input: {
      username: '',
      password: '',
      id_company: '',
      detail: {
        nik: '',
        name: '',
        email: '',
        phone: '',
        role: '',
        description: '',
        isActive: true,
        timestamps: {
          created_by: ''
        }
      }
    },
    resource: {
      company: [],
      role: [
        { value: 0, text: 'Data Input' },
        { value: 1, text: 'Manager' }
      ]
    }
  }),
  watch: {
    selectedCompany: function(val) {
      this.input.detail.role = ''
      this.input.id_company = val.value
      if (val.text == 'TAAWUN') this.resource.role.push({ value: 99, text: 'Administrator' })
      else this.resource.role.splice(2, 1)
    }
  },
  mounted() {
    this.getCompany()
    if (this.id_staff != '') {
      this.title = 'Ubah Data Staff'
      this.isNew = false
      this.getStaff()
    }

    this.input.detail.timestamps.created_by = this.$store.getters.userLoggedIn.id
  },

  methods: {
    getCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company')).then(response => {
          const resData = response.data.data
          this.resource.company = _.sortBy(
            resData.map(r => {
              return {
                text: r.name,
                value: r._id
              }
            }),
            ['text']
          )

          this.loadingCompany = false
        })
      })
    },
    getStaff() {
      try {
        this.loading = true
        this.runApiCalls(() => {})
        axios.get(Constant.apiUrl.concat('/master/staff/', this.id_staff)).then(response => {
          const staff = response.data.data

          this.input.username = _.get(staff, 'username', '')
          this.input.id_company = _.get(staff, 'id_company._id', '')
          this.input.detail.nik = _.get(staff, 'detail.nik', '')
          this.input.detail.name = _.get(staff, 'detail.name', '')
          this.input.detail.email = _.get(staff, 'detail.email', '')
          this.input.detail.phone = _.get(staff, 'detail.phone', '')
          this.input.detail.role = _.get(staff, 'detail.role', '')
          this.input.detail.description = _.get(staff, 'detail.description', '')
          this.input.detail.isActive = _.get(staff, 'detail.isActive', '')
          this.activeRadio = this.input.detail.isActive ? 'yes' : 'no'
          this.companyName = _.get(staff, 'id_company.name', '')

          this.selectedCompany = this.resource.company.filter(
            v => v.value == _.get(staff, 'id_company._id', '')
          )[0]

          this.loading = false
        })
      } catch (error) {
        this.loading = false
      }
    },
    saveStaff() {
      try {
        if (!this.loadingSave) {
          this.loadingSave = true

          this.input.detail.isActive = this.activeRadio == 'yes' ? true : false
          if (!this.id_staff) {
            axios
              .post(Constant.apiUrl.concat('/master/staff/register'), this.input)
              .then(response => {
                if (response.status === 200) {
                  this.loadingSave = false
                  this.onCloseButtonClick()
                  Swal.fire('Berhasil', 'Data Staff Berhasil Disimpan', 'success')
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
              .finally(() => {
                this.loadingSave = false
              })
          } else {
            axios
              .put(Constant.apiUrl.concat('/master/staff/', this.id_staff), this.input)
              .then(response => {
                if (response.status === 200) {
                  Swal.fire('Berhasil', 'Data Staff Berhasil Perbarui', 'success')
                  this.onCloseButtonClick()
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
              .finally(() => {
                this.loadingSave = false
              })
          }
        }
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan staff!',
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}
input:placeholder-shown {
  font-size: 0.5em;
}
</style>
