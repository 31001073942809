<template>
  <v-app id="app">
    <router-view v-slot="{ Component }">
      <transition name="route" mode="in-out">
        <component :is="Component"> </component>
      </transition>
    </router-view>
  </v-app>
</template>

<script>
const axios = require('axios')
const _ = require('lodash')

export default {
  name: 'App',
  beforeMount() {
    axios.defaults.headers.common.Authorization = `Bearer ${this.$store.getters.token}`
    axios.defaults.headers.common.AppsUser = this.$store.getters.userLoggedIn.name
    axios.defaults.headers.common.AppsCompany = this.$store.getters.userLoggedIn.company.name
  },
  mounted() {
    axios.defaults.headers.common.Demo = _.get(this, '$store.getters.userLoggedIn.isDemo')
  }
}
</script>

<style lang="scss">
.route-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.route-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.route-enter-active {
  transition: all 1s ease-in;
}
.route-leave-active {
  transition: all 1s ease-out;
}

a {
  color: inherit !important;
  text-decoration: none;
}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  color: #2c3e50;
}

#nav {
  padding: 30px;
  a {
    font-weight: bold;
    color: #2c3e50;
    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
