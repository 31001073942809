<template>
  <div>
    <v-progress-linear
      v-if="loading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear>
    <transition name="dashboard">
      <div v-if="!loading" class="main-div">
        <h1>Data Anggota dan Santunan</h1>
        <div class="search">
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllSubscription()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
            @keyup="getAllSubscription()"
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="Subscriptions"
          :loading="loading"
          :search="table.search"
          :items-per-page="table.pagination.itemsPerPage"
          class="elevation-0"
        >
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="green">
              mdi-check-outline
            </v-icon>
            <v-icon v-else color="red">
              mdi-close-outline
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-if="isAdmin" icon v-bind="attrs" v-on="on">
                  <v-icon @click="onClickFooter(0, item.id)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <v-btn v-if="!isAdmin" icon v-bind="attrs" v-on="on">
                  <v-icon @click="onClickFooter(0, item.id)">
                    mdi-magnify
                  </v-icon>
                </v-btn>
              </template>
              <span v-if="isAdmin">Ubah data Produk</span>
              <span v-if="!isAdmin">Detail data Produk</span>
            </v-tooltip>
          </template>
        </v-data-table>

        <div class="footer">
          <v-spacer></v-spacer>
          <template v-if="isAdmin">
            <v-btn color="primary" fab dark @click="onClickFooter(0, '')">
              <v-icon dark>
                mdi-plus
              </v-icon>
            </v-btn>
          </template>

          <template> </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      isAdmin: false,
      Subscriptions: [],
      SubscriptionNewStatus: false,
      selectedSubscription: '',
      dialog: '',
      selectedForm: null,
      pages: 0,
      table: {
        search: '',
        headers: [
          { text: 'Bulan', value: 'month', width: '40px' },
          { text: 'Anggota', value: 'member', width: '150px' },
          { text: 'Santunan', value: 'claim', width: '100px' },
          { text: 'Jumlah Iuran', value: 'totalPayment', width: '100px', sortable: false },
          { text: 'Jumlah Santunan', value: 'totalClaim', width: '60px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      }
    }
  },
  watch: {
    Subscriptions: {
      handler() {
        this.$nextTick(() => {
          this.table.pagination.totalItems = this.Subscriptions.length
          if (this.table.pagination.itemsPerPage == null || this.table.pagination.totalItems == null) {
            this.pages = 0
          } else {
            this.pages = Math.ceil(this.table.pagination.totalItems / this.table.pagination.itemsPerPage)
          }
        })
      },
      deep: true
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') this.isAdmin = true
      this.getAllSubscription()
    })
  },
  methods: {
    getAllSubscription(option) {
      this.loading = true
      let sortBy = _.get(option, 'sortDesc[0]', false) ? '-' : ''
      switch (_.get(option, 'sortby[0]', '')) {
        case 'code':
          sortBy = sortBy.concat('code')
          break
        case 'type':
          sortBy = sortBy.concat('type')
          break
        case 'name':
          sortBy = sortBy.concat('name')
          break
        default:
          sortBy = sortBy.concat('code')
          break
      }

      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/Subscription?sort=', sortBy)).then(response => {
          if (response.status == 200) {
            const SubscriptionDataGet = response.data.data
            this.Subscriptions = SubscriptionDataGet.map(Subscription => {
              return {
                id: _.get(Subscription, '_id', '').toString(),
                code: _.get(Subscription, 'code', ''),
                type: _.get(Subscription, 'type', ''),
                name: _.get(Subscription, 'name', ''),
                description: _.get(Subscription, 'description', ''),
                paymentMethod: _.get(Subscription, 'payment_method', ''),
                due: _.get(Subscription, 'due_year', 0)
                  .toString()
                  .concat(' %'),
                dueLength: _.get(Subscription, 'due_min', '')
                  .toString()
                  .concat(' - ', _.get(Subscription, 'due_max', '')),
                isActive: _.get(Subscription, 'isActive', false)
              }
            })
          }
          this.loading = false
        })
      })
    },
    deleteSubscription(items) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: 'Data Subscription akan dihapus',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          if (items.status === true) {
            this.SubscriptionNewStatus = false
          } else {
            this.SubscriptionNewStatus = true
          }
          axios
            .post(Constant.apiUrl.concat('/Subscription/data/status/', items.id), {
              status: this.SubscriptionNewStatus
            })
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Status Subscription Berhasil Diubah', 'success')
                this.getAllSubscription()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      })
    },
    onClickFooter(index, id_Subscription) {
      this.selectedSubscription = id_Subscription
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },
    togglePropsExpanded(props) {
      this.$set(props, 'expanded', !props.expanded)
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllSubscription()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
