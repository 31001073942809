/* eslint-disable prettier/prettier */
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import DashboardPage from '../views/DashboardPage.vue'
import LoginPage from '../views/LoginPage.vue'
import DashboardComponent from '../views/components/dashboard/DashboardComponent.vue'
import About from '../views/About.vue'
import VueSocketIO from 'vue-socket.io-extended'
import { io } from 'socket.io-client'
import moment from 'moment'

import Staff from '../views/Staff.vue'
import Company from '../views/Company.vue'
import Product from '../views/Product.vue'
import Member from '../views/Member.vue'
import Region from '../views/Region.vue'
import Reporting from '../views/Reporting.vue'

import SubscriptionInput from '../views/subscription/SubsInput.vue'
import SubscriptionPayment from '../views/subscription/SubsPayment.vue'
import SubscriptionClaim from '../views/subscription/SubsClaim.vue'
import SubscriptionHistory from '../views/subscription/SubsHistory.vue'

import BalancheSheet from '../views/accounting/BalanceSheet.vue'
import ChartMain from '../views/accounting/ChartMain.vue'
import ChartAccount from '../views/accounting/ChartAccount.vue'
import ChartAccountHelper from '../views/accounting/ChartAccountHelper.vue'
import Journal from '../views/accounting/Journal.vue'
import Ledger from '../views/accounting/Ledger.vue'
import ProfitLoss from '../views/accounting/ProfitLoss.vue'
import SharingFee from '../views/accounting/SharingFee.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: DashboardPage,
    props: true,
    meta: {
      requiresAuth: true
    },

    children: [
      {
        path: '',
        name: 'DashboardComponent',
        component: DashboardComponent,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/region',
        name: 'Master Data Wilayah',
        component: Region,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/company',
        name: 'Master Data Company',
        component: Company,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/staff',
        name: 'Master Data Staff',
        component: Staff,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/product',
        name: 'Master Data Product',
        component: Product,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/member',
        name: 'Member',
        component: Member,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/balancesheet',
        name: 'Balance-Sheet',
        component: BalancheSheet,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/profit-loss',
        name: 'Profit-Loss',
        component: ProfitLoss,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/ledger',
        name: 'Lendger',
        component: Ledger,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/sharing-fee',
        name: 'Fee BMT',
        component: SharingFee,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/journal',
        name: 'Journal',
        component: Journal,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/chartmain',
        name: 'Chart-Main',
        component: ChartMain,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/chartaccount',
        name: 'Chart-Account',
        component: ChartAccount,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/accounting/chartaccounthelper',
        name: 'Chart-Account-Helper',
        component: ChartAccountHelper,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/subscription/input',
        name: 'Subscription-Input',
        component: SubscriptionInput,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/subscription/payment',
        name: 'Subscription-Payment',
        component: SubscriptionPayment,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/subscription/claim',
        name: 'Subscription-Claim',
        component: SubscriptionClaim,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/subscription/reporting',
        name: 'Subscription-Reporting',
        component: Reporting,
        props: true,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/subscription/history',
        name: 'Subscription-History',
        component: SubscriptionHistory,
        props: true,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/login',
    component: LoginPage,
    props: true
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: About
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const isExpired = moment(store.getters.tokenTime * 1000).diff(moment(), 'second') <= 0
  if (to.matched.some(value => value.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null || isExpired) {
      next({
        path: '/login'
      })
    } else {
      if (!Vue.prototype.$socket) {
        Vue.use(
          VueSocketIO,
          io(process.env.VUE_APP_API_URL, {
            auth: { token: localStorage.getItem('token') },
            transports: ['websocket']
          }),
          {
            store,
            actionPrefix: 'SOCKET_',
            mutationPrefix: 'SOCKET_'
          }
        )
      }

      next()
    }
  } else if (to.path === '/login' && localStorage.getItem('token') != null && !isExpired) {
    next({
      path: from.path,
      replace: true
    })
  } else {
    next()
  }
})

export default router
