<template>
  <v-card class="sidenav ">
    <v-navigation-drawer
      v-model="drawer"
      :mini-variant.sync="isMini"
      mini-variant-width="75"
      color="#30383a"
      dark
      permanent
    >
      <v-list-item class="pt-2">
        <router-link tag="div" to="/">
          <a class="anchor-tag" v-if="isMini">
            <img @click="minify = true" width="55" src="@/assets/image/icontaawun.png" alt="Taawun" />
          </a>
          <a v-else class="anchor-tag">
            <img @click="minify = false" width="180" src="@/assets/image/logotaawun.png" alt="Taawun" />
          </a>
        </router-link>
      </v-list-item>
      <v-container class=" ma-0 pa-0 menus">
        <v-list dense>
          <v-list-item
            v-for="item in items"
            :key="item.title"
            :to="item.route"
            class="d-flex justify-center py-2"
            :class="{ white: item.isActive }"
            @click="
              {
                items.forEach(arr => {
                  arr.isActive = arr === item
                })
              }
            "
          >
            <!-- :to="item.route" -->
            <v-list-item-icon class="d-flex justify-center ">
              <v-icon :color="item.isActive ? 'blue lighten-1' : 'white'" :large="item.isActive">
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title
                class="text-sm-left"
                :class="{
                  'subtitle-1': item.isActive,
                  'black--text': item.isActive
                }"
              >
                {{ item.title }}
              </v-list-item-title>
              <v-list-item
                v-for="child in item.children"
                v-show="item.isActive"
                :key="child.title"
                :class="{ grey: child.isActive }"
                :to="child.route"
                @click="
                  {
                    children.forEach(arr => {
                      arr.isActive = arr === child
                    })
                  }
                "
              >
                <!-- :to="item.route" -->

                <v-list-item-content>
                  <v-list-item-title
                    class="text-sm-left"
                    :class="{
                      'subtitle-2': item.isActive,
                      'black--text': item.isActive
                    }"
                  >
                    {{ child.title }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-container>
    </v-navigation-drawer>
    <div v-if="isMini">
      <br />
    </div>
    <div class="tamtech_logo">
      <label class="label" v-if="!isMini">Powerd by :</label>
      <a href="https://tamtech.id" target="_blank" rel="noopener noreferrer">
        <v-img
          v-if="!isMini"
          class=""
          :max-height="logoMaxHeigth"
          :max-width="logoMaxWidth"
          src="@/assets/image/tamtech_full.png"
        ></v-img>
        <v-img
          v-else
          class="tamtech_logo_mini"
          :max-height="logoMaxHeigth"
          :max-width="logoMaxWidth"
          src="@/assets/image/tamtech_icon.png"
          position="center center"
        ></v-img>
      </a>
    </div>
    <div v-if="!isMini" class="d-flex justify-center white--text text-body-2">
      Version {{ version.fe }} / {{ version.be }}
    </div>
  </v-card>
</template>

<script>
import Constant from '@/const'
import ConstantMixin from '@/constantMixin'

const { version } = require('../../package.json')

const _ = require('lodash')
const axios = require('axios')

export default {
  name: 'DashboardSidenav',
  mixins: [ConstantMixin],
  data() {
    return {
      drawer: true,
      items: [],
      logoMaxWidth: 30,
      logoMaxHeigth: 70,
      version: {
        fe: '0.0.0',
        be: '0.0.0'
      }
    }
  },
  watch: {
    isMini: function(val) {
      if (!val) {
        this.logoMaxWidth = 200
        this.logoMaxHeigth = 46
      } else {
        this.logoMaxWidth = 30
        this.logoMaxHeigth = 70
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.version.be = this.$store.getters.apiVersion

      if (this.version.be == '0.0.0') this.getVersion()

      this.version.fe = version
      let role = _.toLower(this.$store.getters.userLoggedIn.role)

      this.items = Constant.menus
        .filter(v => v.isActive)
        .filter(menu => menu.allow.includes(role))
        .map(menu => ({
          ...menu,
          isActive: false,
          children: _.get(menu, 'children', [])
            .filter(menu => menu.allow.includes(role))
            .map(child => ({
              ...child,
              isActive: false
            }))
        }))
    })
  },
  methods: {
    getVersion() {
      axios.get(Constant.apiUrl.concat('/api/ver')).then(response => {
        this.version.be = response.data
        this.$store.commit('apiVersion', response.data)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.sidenav {
  max-height: 80vh;
  .menus {
    max-height: 70vh;
    overflow: auto;
  }
  .title {
    font-family: 'Mont Heavy', Helvetica, sans-serif;
    margin: 0;
    text-align: left;
    padding: 0;
  }
  .subtitle {
    font-family: 'Mont Extra Light', Helvetica, sans-serif;
    margin: 0;
    text-align: right;
    padding: 0;
  }
}
.tamtech_logo_mini {
  margin-left: 20px;
}
.tamtech_logo {
  margin-top: 3vh;
  text-align: left;
  .label {
    font-family: 'Mont Extra Light', Helvetica, sans-serif;
    font-size: 80%;
    color: white;
  }
}
</style>
