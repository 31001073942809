<template>
  <div>
    <!-- <v-progress-linear
      v-if="loading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear> -->
    <transition name="dashboard">
      <div class="main-div">
        <!-- <h1>Data Anggota</h1> -->

        <div class="search">
          <v-btn class="mr-5" v-if="!isAdmin" dark color="primary" @click="onClickFooter(0, '', true)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-autocomplete
                :loading="loadingCompany"
                v-if="isAdmin"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                placeholder="BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>
              <v-autocomplete
                :loading="loadingBranch"
                :items="filter.branch"
                v-if="isAdmin || isHeadquarters"
                v-model="filter.selectedBranch"
                class="px-5"
                label="Cabang BMT"
                placeholder="Cabang"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>

              <v-autocomplete
                :items="filter.list"
                v-model="filter.selected"
                class="px-5"
                label="Filter Periode"
                item-text="name"
                item-value="value"
                single-line
                @change="getAllMember()"
              />
            </v-card>
          </v-menu>

          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllMember()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="members"
          :loading="loading"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          class="elevation-0 pt-5 text-50"
          height="500px"
          hide-default-footer
          @dblclick:row="rowClick"
          item-key="id"
          dense
        >
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="green">
              mdi-check-outline
            </v-icon>
            <v-icon v-else color="red">
              mdi-close-outline
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-list-item @click="onClickFooter(0, item.id, true)">
                  <v-list-item-icon>
                    <v-icon>mdi-magnify</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Lihat data Anggota</v-list-item-title>
                </v-list-item>
                <v-list-item @click="onClickFooter(0, item.id, true)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ubah data Anggota</v-list-item-title>
                </v-list-item>
                <v-list-item v-if="isAdmin" @click="deleteMember(item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-trash-can-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Hapus Data Anggota</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="footer">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllMember()"
            />
          </div>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1200">
              <member
                :id_member="selectedMember"
                :isEdit="isEdit"
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'
import Member from './components/Member'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  components: {
    Member
  },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: false,
      isAdmin: false,
      isEdit: false,
      isUploadLoading: false,
      members: [],
      memberNewStatus: false,
      selectedMember: '',
      dialog: '',
      selectedForm: null,
      pages: 0,
      table: {
        search: '',
        headers: [
          { text: 'Nama', value: 'name', width: '100px' },
          { text: 'NIK', value: 'nik', width: '80px', sortable: false },
          { text: 'Tanggal Lahir', value: 'birthDate', width: '80px' },
          // { text: 'Telepon', value: 'phone', width: '80px' },
          { text: 'Alamat', value: 'address', width: '250px' },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: 'name'
        }
      },
      filter: {
        selected: '',
        selectedCompany: '',
        selectedBranch: '',
        list: [],

        company: [],
        branch: []
      }
    }
  },
  watch: {
    'table.search'(val) {
      this.searchData(this)
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'filter.selectedCompany'(value) {
      this.table.pagination.page = 1
      this.getAllMember()
      // this.getAllSubscriptionHeader()
      this.getBranchCompany(value)
    },
    'filter.selectedBranch'(value) {
      this.table.pagination.page = 1
      this.getAllMember()
      // this.getAllSubscriptionHeader()
    }
  },
  mounted() {
    this.$nextTick(() => {
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
        this.isAdmin = true
        // this.table.headers.unshift({ text: 'BMT', value: 'companyName', width: '150px' })
      } else if (this.$store.getters.userLoggedIn.company.isHeadquarters) {
        this.isHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
        this.getBranchCompany(this.$store.getters.userLoggedIn.company.id)

        // this.table.headers.unshift({ text: 'Cabang', value: 'branch', width: '150px' })
      }
      this.getAllCompany()

      this.filter.list = this.monthFilter()
      this.filter.selected = this.filter.list[0].value
      this.getAllMember()
    })
  },
  methods: {
    rowClick(event, row) {
      this.onClickFooter(0, row.item.id, false)
    },
    setPageOne() {
      this.table.pagination.page = 1
    },
    searchData: _.debounce(v => {
      v.getAllMember()
      this.setPageOne()
    }, 650),
    getAllCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
          if (response.status == 200) {
            const companyDataGet = response.data.data
            this.filter.company = companyDataGet
              .filter(v => v.name !== 'ADMINISTRATOR')
              .map(company => {
                return {
                  value: _.get(company, '_id', '').toString(),
                  text: _.get(company, 'name', '')
                }
              })
          }
          this.loadingCompany = false
        })
      })
    },
    getBranchCompany(id_company) {
      this.loadingBranch = true
      this.runApiCalls(() => {
        if (id_company) {
          axios.get(Constant.apiUrl.concat(`/master/company/branch/${id_company}`)).then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.filter.branch = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR')
                .map(company => {
                  return {
                    value: _.get(company, '_id', '').toString(),
                    text: _.get(company, 'headquarters.description', '')
                  }
                })

              if (this.isAdmin) {
                this.filter.branch.unshift({ value: this.filter.selectedCompany, text: 'PUSAT' })
                this.filter.branch.unshift({ value: '', text: 'ALL' })
              } else {
                this.filter.branch.unshift({
                  value: this.$store.getters.userLoggedIn.company.id,
                  text: 'PUSAT'
                })
                this.filter.selectedBranch = this.$store.getters.userLoggedIn.company.id
              }
            }
            this.loadingBranch = false
          })
        } else {
          this.loadingBranch = false
          this.filter.selectedBranch = ''
          this.filter.branch = []
        }
      })
    },
    getAllMember(option) {
      this.loading = true
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany
      if (this.filter.selectedBranch)
        company =
          this.filter.selectedBranch == 'Pusat'
            ? this.$store.getters.userLoggedIn.company.id
            : this.filter.selectedBranch

      let isHq = ''
      if (
        (this.isHeadquarters || this.isAdmin) &&
        !this.filter.selectedBranch &&
        this.filter.selectedBranch != this.filter.selectedCompany
      )
        isHq = '1'
      try {
        let sortBy = _.get(option, 'sortDesc[0]', false) ? '-' : ''
        switch (_.get(option, 'sortby[0]', '')) {
          case 'name':
            sortBy = sortBy.concat('name')
            break
          case 'nik':
            sortBy = sortBy.concat('nik')
            break
          default:
            sortBy = sortBy.concat('name')
            break
        }

        this.pages = 0
        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                '/member?page=',
                this.table.pagination.page,
                '&itemCount=',
                this.table.pagination.itemsPerPage,
                '&sort=',
                sortBy,
                '&search=',
                this.table.search,
                '&filter=',
                this.filter.selected || '',
                '&company=',
                company || '',
                '&headquarters=',
                isHq
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.pages = 0
                this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)

                this.members = []
                this.members = data.map(member => {
                  return {
                    id: _.get(member, '_id', '').toString(),
                    companyName: _.get(member, 'id_company.name', ''),
                    name: _.get(member, 'name', ''),
                    nik: _.get(member, 'nik', ''),
                    phone: _.get(member, 'phone', ''),
                    address: _.get(member, 'address.description', ''),
                    birthDate: moment(_.get(member, 'birth_date'))
                      .locale('id')
                      .format('D MMMM YYYY'),
                    isActive: _.get(member, 'isActive', '')
                  }
                })
              } else {
                this.members = []
              }
              this.loading = false
            })
            .catch(error => {
              this.pages = 0
              this.members = []
              this.loading = false
            })
        })
      } catch (error) {
        this.loading = false
      }
    },
    deleteMember(item) {
      try {
        Swal.fire({
          title: 'Anda yakin akan menghapus anggota ?',
          text: `Data tidak bisa dikembalikan`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        })
          .then(result => {
            if (result.value) {
              this.runApiCalls(() => {
                axios
                  .post(Constant.apiUrl.concat('/member/delete/', item))
                  .then(() => {
                    Swal.fire('Berhasil!', 'Anggota berhasil dihapus', 'success')
                    this.getAllMember()
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: message
              })
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menyambung ke server'
              })
            } else {
              // eslint-disable-next-line
              console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    // uploadMembers() {
    //   try {
    //     Swal.fire({
    //       title: 'Pembayaran',
    //       text: 'Silahkan pilih metode pembayaran',
    //       icon: 'warning',
    //       showDenyButton: true,
    //       showCancelButton: true,
    //       confirmButtonColor: '#3085d6',
    //       cancelButtonColor: '#d33',
    //       denyButtonColor: '#34eb58',
    //       confirmButtonText: 'Pusat',
    //       denyButtonText: `Cabang`,
    //       cancelButtonText: 'Batal'
    //     }).then(response => {
    //       if (response.isConfirmed || response.isDenied) {
    //         let isPaidFromBranch = null
    //         if (response.isConfirmed) isPaidFromBranch = false
    //         else if (response.isDenied) isPaidFromBranch = true

    //         Swal.fire({
    //           title: 'Apakah anda yakin?',
    //           text: `Pembayaran akan di lakukan oleh ${isPaidFromBranch ? 'Cabang' : 'Pusat'} ?`,
    //           icon: 'warning',
    //           showCancelButton: true,
    //           confirmButtonColor: '#3085d6',
    //           cancelButtonColor: '#d33',
    //           confirmButtonText: 'Ya',
    //           cancelButtonText: 'Batal'
    //         }).then(result => {
    //           if (result.isConfirmed) {
    //             this.runApiCalls(() => {
    //               axios
    //                 .put(
    //                   Constant.apiUrl.concat('/subscription/status/', item.id, '?company=', item.id_company),
    //                   {
    //                     paid: {
    //                       periode: {
    //                         month: moment()
    //                           .locale('id')
    //                           .format('MMMM'),
    //                         year: moment()
    //                           .locale('id')
    //                           .format('YYYY')
    //                       },
    //                       status: 'requested',
    //                       description: ''
    //                     },
    //                     staff: this.$store.getters.userLoggedIn.id,
    //                     isPaidFromBranch
    //                   }
    //                 )
    //                 .then(response => {
    //                   if (response.status === 200) {
    //                     this.$socket.client.emit('payment:update', {
    //                       company: this.$store.getters.userLoggedIn.company.name,
    //                       staff: this.$store.getters.userLoggedIn
    //                     })
    //                     this.subscriptions = []
    //                     this.getAllSubscription(false)
    //                   }
    //                   Swal.fire(
    //                     'Pembayaran',
    //                     `Pembayaran berhasil terdaftar ${isPaidFromBranch ? '' : 'di Pusat'}`,
    //                     'success'
    //                   )
    //                 })
    //                 .catch(error => {
    //                   if (error.response) {
    //                     const message = error.response.data.pesan
    //                     Swal.fire({
    //                       icon: 'error',
    //                       title: 'Terjadi Kesalahan',
    //                       text: message
    //                     })
    //                   } else if (error.request) {
    //                     Swal.fire({
    //                       icon: 'error',
    //                       title: 'Terjadi Kesalahan',
    //                       text: 'Tidak dapat menyambung ke server'
    //                     })
    //                   } else {
    //                     // eslint-disable-next-line
    //                     console.log('Something happened in the middle of request')
    //                   }
    //                 })
    //             })
    //           }
    //         })
    //       }
    //     })
    //   } catch (error) {
    //     Swal.fire({
    //       icon: 'error',
    //       title: 'Terjadi Kesalahan',
    //       text: 'Tidak dapat menyambung ke server'
    //     })
    //   }
    // },
    updateStatusMember(items) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: 'Data member akan dihapus',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          if (items.status === true) {
            this.memberNewStatus = false
          } else {
            this.memberNewStatus = true
          }
          axios
            .post(Constant.apiUrl.concat('/member/data/status/', items.id), {
              status: this.memberNewStatus
            })
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Status Member Berhasil Diubah', 'success')
                this.getAllMember()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      })
    },
    onClickFooter(index, id_member, isEdit) {
      this.isEdit = isEdit
      this.selectedMember = id_member

      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },
    togglePropsExpanded(props) {
      this.$set(props, 'expanded', !props.expanded)
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllMember()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.autocomplete {
  max-width: 14%;
  margin-right: 2%;
}
.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  // display: flex;
  // position: relative;
  // align-items: center;
  // justify-content: flex-start;
  right: 0;
}

.input {
  max-width: 30%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
