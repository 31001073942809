<template>
  <main class="main">
    <transition name="chartAccount-view">
      <v-container id="chartAccount-view" fluid tag="section">
        <div class="search mt-n3 pb-3">
          <v-btn color="primary" @click="openDialog(0, null)">
            <v-icon>mdi-plus</v-icon>
          </v-btn>

          <v-spacer></v-spacer>
        </div>

        <div class="datatable">
          <v-data-table
            :headers="table.headers"
            :items="chartAccounts"
            hide-default-footer
            :loading="isLoading"
            :search="table.serach"
            disable-pagination
            item-key="name"
            class="elevation-1"
            dense
          >
            <template v-slot:[`item.actions`]="{ item }">
              <v-menu bottom origin="center center" transition="scale-transition">
                <template v-slot:activator="{ on: menu, attrs }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on: tooltip }">
                      <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                        <v-icon dark> mdi-menu </v-icon>
                      </v-btn>
                    </template>
                    <span>Menu</span>
                  </v-tooltip>
                </template>
                <v-list dense>
                  <v-list-item v-if="item.isAllowAddSub" @click="openDialog(0, item)">
                    <v-list-item-icon>
                      <v-icon>mdi-plus</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Tambah Sub Akun</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="deleteAccount(item._id)">
                    <v-list-item-icon>
                      <v-icon>mdi-delete</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>Hapus Akun/Sub Akun</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
          </v-data-table>
        </div>
        <!-- <div class="datatable-action">
          <v-pagination
            v-model="pagination.page"
            :length="journalsPage"
            :total-visible="5"
            @input="getAllJournal()"
          ></v-pagination>
        </div> -->
      </v-container>
    </transition>
    <v-dialog v-model="isDialog" :max-width="700">
      <chart-account-input
        v-if="selectedForm == 0"
        :account="selectedItem"
        @change-form="changeForm"
        @close-form="closeForm"
      />
    </v-dialog>
  </main>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'
import ChartAccountInput from '../components/accounting/ChartAccountInput.vue'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')
export default {
  name: 'ChartAccount',
  components: { ChartAccountInput },
  mixins: [ConstantMixin],
  data() {
    return {
      isLoading: false,
      isDialog: false,
      isAdmin: false,
      selectedForm: '',
      selectedItem: null,
      isNewAccount: false,
      isAccounting: false,
      table: {
        search: '',
        headers: [
          {
            text: 'Kode Akun',
            value: 'codeDisplay',
            width: '150px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Nama',
            value: 'name',
            width: '150px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'K/D',
            value: 'creditDebit',
            width: '80px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          // {
          //   text: 'Saldo',
          //   value: 'nominal',
          //   width: '90px',
          //   sortable: false,
          //   class: 'text-subtitle-1 font-weight-black',
          // },
          {
            text: '',
            align: 'center',
            value: 'actions',
            width: '50px',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          }
        ]
      },
      chartAccounts: [],
      chartAccountsPage: 0
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllChartAccount()
      this.isAccounting = this.$store.getters.userLoggedIn.role == 'accounting'
    })
  },
  watch: {
    isDialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'table.search'(val) {
      this.pagination.page = 1
      setTimeout(this.getAllChartAccount(), 300)
    }
  },
  computed: {},
  methods: {
    getAllChartAccount(option) {
      try {
        this.isLoading = true
        this.runApiCalls(() => {
          axios
            .get(Constant.apiUrl.concat('/accounting/chartaccount?search=', this.table.search))
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data

                this.chartAccountsPage = Math.ceil(length / this.pagination.rowsPerPage)

                this.chartAccounts = data.map(c => {
                  const padding = c.code.split('.').filter(v => v == 0).length
                  let pad = ''
                  if (padding == 1) pad = '- - -'
                  if (padding == 0) pad = '- - - - - -'

                  return {
                    ...c,
                    codeDisplay: `${pad} ${c.code}`,
                    isAllowAddSub: c.code.split('.').filter(v => v > 0).length < 3,
                    creditDebit: c.isDebit ? 'Debit' : 'Kredit',
                    name: c.isNegative ? `(${c.name})` : c.name,
                    nominal: this.getCurrency(c.nominal)
                  }
                })
              }
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
              // console.log(error)
            })
        })
      } catch {
        this.isLoading = false
      }
    },
    deleteAccount(id_account) {
      try {
        Swal.fire({
          title: 'Anda yakin akan menghapus Akun ?',
          text: `Data tidak bisa dikembalikan, Akun harus tidak memiliki saldo`,
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        })
          .then(result => {
            if (result.value) {
              this.runApiCalls(() => {
                axios
                  .delete(Constant.apiUrl.concat('/accounting/chartaccount/', id_account))
                  .then(() => {
                    Constant.swalToast('success', 'Akun Pembantu berhasil dihapus')
                    this.getAllChartAccount()
                  })
                  .catch(error => {
                    if (error.response) {
                      const message = error.response.data.pesan
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: message
                      })
                    } else if (error.request) {
                      Swal.fire({
                        icon: 'error',
                        title: 'Terjadi Kesalahan',
                        text: 'Tidak dapat menyambung ke server'
                      })
                    } else {
                      // eslint-disable-next-line
                      console.log('Something happened in the middle of request')
                    }
                  })
              })
            }
          })
          .catch(error => {
            if (error.response) {
              const message = error.response.data.pesan
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: message
              })
            } else if (error.request) {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Tidak dapat menyambung ke server'
              })
            } else {
              // eslint-disable-next-line
              console.log('Something happened in the middle of request')
            }
          })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Tidak dapat menyambung ke server'
        })
      }
    },
    openDialog(index, item) {
      this.selectedItem = item

      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.isDialog = true
    },
    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.isDialog = false
      this.getAllChartAccount()
    }
  }
}
</script>

<style>
.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}
</style>
