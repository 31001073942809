/* eslint-disable no-unused-vars */
import Swal from 'sweetalert2'
import moment from 'moment'

const _ = require('lodash')
const axios = require('axios')

const { version } = require('../package.json')

import createCertificate from '/src/utils/certificate.js'
import Constant from '@/const'

export default {
  computed: {
    isMini() {
      return this.$store.getters.isMini
    },
    isJwtVerified() {
      return this.$store.getters.isJwtVerified
    },
    isDemo() {
      return _.get(this, '$store.getters.userLoggedIn.isDemo', false)
    },
    route() {
      return this.$route.path
    },
    routeSplit() {
      return this.$route.path.split('/')
    },
    routeController() {
      return this.routeSplit[1]
    },
    routeParameter() {
      return this.routeSplit[2]
    },
    userMenus() {
      return this.$store.getters.userLoggedIn.menus
    },
    userLoggedIn() {
      return this.$store.getters.userLoggedIn
    },
    isAdmin() {
      return this.$store.getters.userLoggedIn.role == 'administrator'
    },
    breakpoint() {
      return this.$vuetify.breakpoint.name
    }
  },
  data() {
    return {
      mini: true,
      footerOptions: {
        itemsPerPageOptions: [5, 10, 15, 20],
        itemsPerPageText: 'Baris :'
      },
      buildEnv: process.env.VUE_APP_ENVIRONMENT,
      version: {
        fe: version,
        be: '0.0.0'
      },
      pagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10
      }
    }
  },
  methods: {
    generateCertificate({ staff, company, id_subscription, member }) {
      createCertificate({ staff, company, id_subscription, member })
    },
    generateClaimCertificate({ staff, company, id_subscription, member }) {
      createCertificate({ staff, company, id_subscription, member, isClaim: true })
    },
    runApiCalls(apiCalls = null) {
      if (apiCalls === null) return

      if (this.isJwtVerified) {
        apiCalls()
      } else {
        this.showErrorJwt()
      }
    },
    showErrorJwt() {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: 'Token sudah tidak aktif',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Log out',
        allowEscapeKey: false,
        allowOutsideClick: false
      }).then(result => {
        if (result.value) {
          delete axios.defaults.headers.Authorization
          localStorage.removeItem('token')
          this.$store.commit('removeToken')
          this.$router.push('/login')
          document.location.reload()
        }
      })
    },
    showFinishPatientModal(data) {
      Swal.fire({
        title: 'Proses ini tidak dapat dibatalkan!',
        text: `Apakah ${data.text}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya, sudah selesai'
      }).then(result => {
        if (result.value) {
          this.changePatientStatus(data.patient, data.status)
          Swal.fire('Berhasil!', `${_.startCase(data.text)}.`, 'success')
        }
      })
    },
    showErrorAxios(error) {
      if (error.response) {
        const { pesan } = error.response.data
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: pesan
        })
      } else if (error.request) {
        Swal.fire({
          icon: 'error',
          title: 'Tidak dapat menyambung ke server',
          text: 'Periksa koneksi internet anda'
        })
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Error tidak diketahui'
        })(
          // eslint-disable-next-line
          error
        )
      }
    },
    getResourceError(error) {
      if (!error.response) {
        if (error.request) {
          Swal.fire({
            icon: 'error',
            title: 'Tidak dapat menyambung ke server',
            text: 'Periksa koneksi internet anda'
          })
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Detail Error tidak diketahui'
          })

          // eslint-disable-next-line
        }
      }
    },
    swalLoading() {
      Swal.fire({
        showConfirmButton: false,
        toast: true,
        title: 'Mohon tunggu . . .',
        position: 'top-end',
        allowEscapeKey: false,
        allowOutsideClick: false,
        allowEnterKey: false,

        didOpen: () => {
          Swal.showLoading()
        }
      })
    },
    swalLoadingWithMessage(msg) {
      Swal.fire({
        showConfirmButton: false,
        toast: true,
        title: msg,
        position: 'top-end',
        allowEscapeKey: false,
        allowOutsideClick: false,
        allowEnterKey: false,
        didOpen: () => {
          Swal.showLoading()
        }
      })
    },
    swalToast(icon, msg) {
      Swal.fire({
        showConfirmButton: false,
        toast: true,
        title: msg,
        icon: icon,
        timer: 4000,
        timerProgressBar: true,
        position: 'top-end',
        didOpen: toast => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
    },
    showFillWarning(hightlight, message) {
      Swal.fire({
        icon: 'warning',
        title: hightlight,
        text: message
      })
    },
    showFillError(hightlight, message) {
      Swal.fire({
        icon: 'error',
        title: hightlight,
        text: message
      })
    },
    showFillSuccess(hightlight, message) {
      Swal.fire({
        icon: 'success',
        title: hightlight,
        text: message
      })
    },
    showFailedPrint() {
      Swal.fire({
        icon: 'error',
        title: 'Terjadi Kesalahan',
        text: 'Tidak dapat mencetak pdf'
      })
      // eslint-disable-next-line
    },
    datePickerFormat(date) {
      return date
        ? moment(date)
            .locale('id')
            .format('D MMMM YYYY')
        : ''
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        $event.preventDefault()
      }
    },
    getCurrency(nominal) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0
      }).format(nominal || 0)
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    },
    monthFilter() {
      let list = []
      let year = moment().format('YYYY')
      let month = moment().format('M')
      let diff = moment().diff('2020-12-01', 'month')

      for (let i = diff; i >= 1; i--) {
        list.push({
          name: moment(`${year}-${month}-1`)
            .locale('id')
            .format('MMMM YYYY'),
          value: moment(`${year}-${month}-1`)
            .startOf('month')
            .format('YYYY-MM-DD')
        })
        if (month == 1) {
          year -= 1
          month = 12
        } else {
          month -= 1
        }
      }
      return list
    },
    downloadDocument(claimDocuments) {
      try {
        for (const claimDocument of claimDocuments) {
          let path = claimDocument.files[0].path.split('/')
          path.shift()
          path = path.join('/')
          axios
            .get(Constant.apiUrl.concat(`/${path}`), { responseType: 'arraybuffer' })
            .then(response => {
              const url = window.URL.createObjectURL(new Blob([response.data]))
              const link = document.createElement('a')
              const memberName = path
                .split('/')
                [path.split('/').length - 4].split(' ')[0]
                .toLowerCase()
              const fileName = path
                .split('/')
                [path.split('/').length - 2].split(' ')
                .join('_')
              const fileType = path
                .split('/')
                [path.split('/').length - 1].split('.')
                [path.split('/')[path.split('/').length - 1].split('.').length - 1].toLowerCase()

              link.href = url
              link.setAttribute('download', `${memberName}-${fileName}.${fileType}`)
              document.body.appendChild(link)
              link.click()
            })
            .catch(error => {})
        }
      } catch (error) {
        if (error.response) {
          const message = error.response.data.pesan
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: message
          })
        } else if (error.request) {
          Swal.fire({
            icon: 'error',
            title: 'Terjadi Kesalahan',
            text: 'Tidak dapat menyambung ke server'
          })
        } else {
          // eslint-disable-next-line
          console.log('Something happened in the middle of request')
        }
      }
    }
  }
}
