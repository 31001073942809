<template>
  <div class="main-div">
    <div class="search">
      <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" :loading="loadingFilter" dark v-bind="attrs" v-on="on">
            Periode
          </v-btn>
        </template>

        <v-card>
          <div class="d-flex flex-column justify-center">
            <!-- <v-autocomplete
                v-if="isAdmin"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                placeholder="BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete> -->

            <!-- <v-autocomplete
              :items="filter.list"
              v-model="filter.selected"
              class="px-5"
              label="Filter Periode"
              item-text="name"
              item-value="value"
              single-line
            /> -->
            <v-date-picker
              v-model="filter.selectedDate"
              locale="id"
              type="month"
              min="2023-01"
            ></v-date-picker>
          </div>
        </v-card>
      </v-menu>
      <div class="ml-10">
        <span
          ><b>Periode :</b>
          {{ displayPeriode }}
        </span>
      </div>
      <div class="ml-10">
        <span
          ><b>Rate :</b>
          {{ rate }}
        </span>
      </div>
      <v-spacer></v-spacer>
      <v-btn
        class="mx-2"
        fab
        small
        @click="
          table.search = ''
          getAllSharingFee
        "
        color="primary"
      >
        <v-icon>
          mdi-autorenew
        </v-icon>
      </v-btn>
      <v-text-field
        v-model="table.search"
        prepend-icon="mdi-magnify"
        class="input"
        label="Cari..."
        single-line
        hide-details
      ></v-text-field>
    </div>

    <v-data-table
      :headers="table.headers"
      :items="table.sharingFee"
      :search="table.search"
      class="elevation-1 pt-5 text-50"
      hide-default-footer
      disable-pagination
      item-key="id"
      dense
    ></v-data-table>
  </div>
</template>

<script>
import Constant from '@/const'
import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')
export default {
  name: 'SharingFee',
  components: {},
  mixins: [ConstantMixin],
  data() {
    return {
      loadingFilter: false,
      loading: false,
      table: {
        search: '',
        sharingFee: [],
        headers: [
          { text: 'BMT', value: 'company_name', width: '100px', sortable: false },
          {
            text: 'Menunggu Persetujuan',
            value: 'not_confirm_total',
            width: '200px',
            sortable: false,
            align: 'end'
          },
          {
            text: 'Pembayaran Disetujui',
            value: 'confirm_total',
            width: '200px',
            sortable: false,
            align: 'end'
          },
          { text: 'Bagi Hasil', value: 'fee', width: '200px', sortable: false, align: 'end' },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ]
      },
      filter: {
        selectedDate: '',
        selectedCompany: '',
        date: [],
        company: []
      },
      displayPeriode: '',
      rate: ''
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.filter.selectedDate = moment().format('YYYY-MM')
      // this.getCompanyAccountHelper()
      // this.getAllSharingFee()
    })
  },
  watch: {
    filter: {
      handler: function(val, oldVal) {
        this.getAllSharingFee()
        this.displayPeriode = `${moment(val.selectedDate)
          .locale('id')
          .format('MMMM')} ${moment(val.selectedDate)
          .locale('id')
          .format('YYYY')}`
      },
      deep: true
    }
  },
  methods: {
    getCompanyAccountHelper() {
      this.loadingFilter = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/accounting/chartaccounthelper/bmt')).then(response => {
          if (response.status == 200) {
            this.filter.company = response.data.data.map(company => {
              return {
                value: _.get(company, '_id', '').toString(),
                text: _.get(company, 'name', '')
              }
            })
          }
          this.loadingFilter = false
        })
      })
    },
    getAllSharingFee() {
      this.loading = true
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat(`/payment/shared-fee?periode=${this.filter.selectedDate}`))
          .then(response => {
            if (response.status == 200) {
              this.rate = `${response.data.data.rate} %`
              this.table.sharingFee = response.data.data.items.map(sf => {
                return {
                  ...sf,
                  not_confirm_total: this.getCurrency(sf.not_confirm_total),
                  confirm_total: this.getCurrency(sf.confirm_total),
                  fee: this.getCurrency(sf.fee)
                }
              })
            }
            this.loading = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.autocomplete {
  max-width: 14%;
  margin-right: 2%;
}
.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
