<template>
  <div class="">
    <div class="d-flex align-center  pt-2 pl-10">
      <v-combobox v-model="selectedItem" :items="resource.account" class="input" label="Pilih Akun..." />
      <v-menu
        v-if="selectedItem"
        v-model="menu"
        :close-on-content-click="false"
        :nudge-width="300"
        allow-overflow
        offset-x
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn class="ml-10" color="primary" v-bind="attrs" v-on="on">
            Filter
          </v-btn>
        </template>
        <v-card class="pa-3">
          <v-autocomplete
            :loading="loadingAccountHelper"
            :items="resource.accountHelper"
            v-model="filter.accountHelper"
            label="Filter Akun Pembantu"
            placeholder="Akun Pembantu"
            :item-text="text => `${text.name} (${text.nominal})`"
            item-value="_id"
            single-line
            clearable
          ></v-autocomplete>
          <v-menu
            ref="filterStartDateMenu"
            v-model="filterStartDateMenu"
            :close-on-content-click="false"
            :return-value.sync="filter.startDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="filterdate"
                dense
                placeholder="Masukkan Tanggal Awal"
                label="Tanggal Awal"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="startDateFormattedMomentjs"
                @click:clear="filter.startDate = ''"
              ></v-text-field>
            </template>
            <v-date-picker
              @click:date="
                $refs.filterStartDateMenu.save(filter.startDate)
                filterStartDateMenu = false
              "
              v-model="filter.startDate"
              locale="id-ID"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="filterStartDateMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.filterStartDateMenu.save(filter.startDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

          <v-menu
            ref="filterEndDateMenu"
            v-model="filterEndDateMenu"
            :close-on-content-click="false"
            :return-value.sync="filter.endDate"
            transition="scale-transition"
            offset-y
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                id="filterdate"
                dense
                placeholder="Masukkan Tanggal Akhir"
                label="Tanggal Akhir"
                readonly
                v-bind="attrs"
                v-on="on"
                :value="endDateFormattedMomentjs"
                @click:clear="filter.endDate = ''"
              ></v-text-field>
            </template>
            <v-date-picker
              @click:date="
                $refs.filterEndDateMenu.save(filter.endDate)
                filterEndDateMenu = false
              "
              v-model="filter.endDate"
              locale="id-ID"
              no-title
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="filterEndDateMenu = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.filterEndDateMenu.save(filter.endDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-card>
      </v-menu>
      <v-btn v-if="selectedItem" class="ml-10" @click="openDialog(0, selectedItem)" color="primary">
        Saldo Awal
      </v-btn>

      <v-spacer></v-spacer>
      <v-row v-if="selectedItem">
        <v-col>
          <b> <h2>Saldo Saat ini :</h2> </b>
        </v-col>
        <v-col>
          <b>
            <h2>{{ getCurrency(selectedItem.nominal) }}</h2>
          </b>
        </v-col>
      </v-row>
    </div>
    <div class="d-flex justify-center">
      <v-data-table
        class="datatable"
        :headers="table.headers"
        :items="table.items"
        :page.sync="table.pagination.page"
        hide-default-footer
        :loading="isLoading"
        disable-pagination
        item-key="name"
        dense
      >
      </v-data-table>
    </div>
    <div v-if="selectedItem" class="pagination">
      <v-pagination
        v-model="table.pagination.page"
        :length="table.pagination.pages"
        :total-visible="7"
        @input="getAllLedger()"
      />
      <v-spacer></v-spacer>
    </div>
    <v-dialog v-model="isDialog" :max-width="600">
      <ledger-initial-balance
        v-if="selectedForm == 0"
        :account="selectedItem"
        @change-form="changeForm"
        @close-form="closeForm"
      />
    </v-dialog>
  </div>
</template>

<script>
import ConstantMixin from '@/constantMixin'
import Constant from '@/const'
import LedgerInitialBalance from '../components/accounting/LedgerInitialBalance.vue'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')

export default {
  name: 'Ledger',
  components: { LedgerInitialBalance },
  mixins: [ConstantMixin],
  data() {
    return {
      menuCode: 'b-03',
      access: {
        write: false,
        update: false,
        delete: false
      },
      isLoading: false,
      isDialog: false,
      loadingAccountHelper: false,
      filterStartDateMenu: false,
      filterEndDateMenu: false,
      selectedForm: '',
      selectedItem: null,
      filter: {
        accountHelper: '',
        startDate: '',
        endDate: ''
      },
      currentNominal: '',
      journals: [],
      table: {
        headers: [
          {
            text: 'Tanggal',
            value: 'date',
            width: '50px',
            sortable: false,
            align: 'left',
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Jenis Jurnal',
            value: 'journalType',
            width: '50px',
            align: 'left',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Akun Pembantu',
            value: 'accountHelper',
            width: '100px',
            align: 'left',
            sortable: false,
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Transaksi',
            value: 'description',
            width: '250px',
            sortable: false,
            align: 'left',
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Debit',
            value: 'debit',
            width: '50px',
            sortable: false,
            align: 'left',
            class: 'text-subtitle-1 font-weight-black'
          },
          {
            text: 'Kredit',
            value: 'credit',
            width: '50px',
            sortable: false,
            align: 'left',
            class: 'text-subtitle-1 font-weight-black'
          }
        ],
        items: [],
        pagination: {
          pages: 1,
          page: 1,
          rowsPerPage: 20
        }
      },
      ledgers: [],
      ledgersPage: 0,
      resource: {
        account: [],
        accountHelper: []
      }
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.filter.startDate = moment()
        .add(-7, 'days')
        .format()
      this.filter.endDate = moment().format()
      this.getChartAccount()
    })
  },
  watch: {
    filter: {
      handler() {
        this.$nextTick(() => {
          this.getAllLedger()
        })
      },
      deep: true
    },
    isDialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    selectedItem(val) {
      this.getAccountHelper()
      this.getAllLedger()
    }
  },
  computed: {
    startDateFormattedMomentjs() {
      return this.filter.startDate
        ? moment(this.filter.startDate)
            .locale('id')
            .format('D MMMM YYYY')
        : ''
    },
    endDateFormattedMomentjs() {
      return this.filter.endDate
        ? moment(this.filter.endDate)
            .locale('id')
            .format('D MMMM YYYY')
        : ''
    }
  },
  methods: {
    getAccountHelper() {
      this.resource.accountHelper = []
      this.runApiCalls(() => {
        axios
          .get(Constant.apiUrl.concat('/accounting/chartaccounthelper?account=', this.selectedItem.value))
          .then(response => {
            if (response.status == '200') {
              this.resource.accountHelper = response.data.data.map(d => {
                return {
                  name: d.name,
                  nominal: this.getCurrency(d.nominal),
                  _id: d._id
                }
              })
            }
            this.isLoading = false
          })
      })
    },
    getChartAccount() {
      try {
        this.isLoading = true
        this.runApiCalls(() => {
          axios
            .get(Constant.apiUrl.concat('/accounting/chartaccount'))
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data
                this.resource.account = []
                this.resource.account = data
                  .map(c => {
                    return {
                      text: `${c.code} - ${c.name}`,
                      value: c._id.toString(),
                      splitCode: c.code.split('.'),
                      nominal: c.nominal
                    }
                  })
                  .filter(v => v.splitCode[2] != '0')
              }
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
              // console.log(error)
            })
        })
      } catch {
        this.isLoading = false
      }
    },

    getAllLedger(option) {
      try {
        this.isLoading = true

        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                `/accounting/journal/ledger/${this.selectedItem.value}`,
                `?page=${this.table.pagination.page}`,
                `&itemCount=${this.table.pagination.rowsPerPage}`,
                `&startDate=${moment(this.filter.startDate).format('YYYY-MM-DD')}`,
                `&endDate=${moment(this.filter.endDate).format('YYYY-MM-DD')}`,
                `&accountHelper=${this.filter.accountHelper || ''}`
              )
            )
            .then(response => {
              if (response.status == 200) {
                const { data, length } = response.data

                this.table.pagination.pages = 1
                this.table.pagination.pages = Math.ceil(length / this.table.pagination.rowsPerPage)
                this.table.items = []
                this.table.items = data.map(c => {
                  const journalType = c.code.split('/')[0]
                  return {
                    journalType,
                    date: moment(c.date)
                      .locale('id')
                      .format('D MMMM YYYY'),
                    description: c.description,
                    accountHelper: c.accountHelper?.name,
                    debit: this.getCurrency(c.debit),
                    credit: this.getCurrency(c.credit)
                  }
                })
              }
              this.isLoading = false
            })
            .catch(error => {
              this.isLoading = false
              // console.log(error)
            })
        })
      } catch {
        this.isLoading = false
      }
    },
    deleteledger(id_ledger) {},
    openDialog(index, item) {
      this.selectedItem = item
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.isDialog = true
    },
    changeForm(item) {
      this.selectedForm = item
    },
    closeForm(isSaved = false) {
      this.isDialog = false
      if (isSaved) {
        this.getChartAccount()
        this.table.items = []
        this.selectedItem = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.input {
  max-width: 20%;
}
.datatable {
  display: inline-block;
  width: 95%;
}
.main {
  width: 100%;
  flex-direction: column;
  position: relative;
  height: 50%;
  overflow-y: off;
  overflow-x: off;
}
.datatable-action {
  position: absolute;
  left: 10px;
  bottom: -8vh;
}

.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.btn-new {
  right: 50px;
  bottom: 1vh;
}
</style>
