<template>
  <v-main>
    <v-tabs v-model="tab" class="mb-5" background-color="transparent" grow>
      <v-tab v-for="item in items" :key="item.tab">
        {{ item.tab }}
      </v-tab>
    </v-tabs>
    <chart-account v-if="tab == 0"></chart-account>
    <chart-account-helper v-if="tab == 1"></chart-account-helper>
  </v-main>
</template>

<script>
import ChartAccount from './ChartAccount.vue'
import ChartAccountHelper from './ChartAccountHelper.vue'
import ConstantMixin from '@/constantMixin'

export default {
  name: 'ChartMain',
  components: { ChartAccount, ChartAccountHelper },
  mixins: [ConstantMixin],
  data() {
    return {
      tab: 0,
      items: [
        { tab: 'Akun', content: 'chart-account' },
        { tab: 'Akun Pembantu', content: 'chart-account-helper' }
      ]
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isAccounting = this.$store.getters.userLoggedIn.role == 'accounting'
    })
  },
  watch: {
    tab(val) {}
  },
  computed: {},
  methods: {}
}
</script>

<style></style>
