import Vue from 'vue'
import Vuex from 'vuex'

const moment = require('moment')
const jwt = require('jsonwebtoken')

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isMini: true,
    isConnected: false,
    socketMessage: '',
    token: localStorage.getItem('token'),
    apiVersion: '0.0.0'
  },
  getters: {
    isMini: state => state.isMini,
    apiVersion: state => state.apiVersion,
    isSocketConnect: state => state.isConnected,
    token: state => state.token,
    tokenTime: state => {
      try {
        const decoded = jwt.decode(state.token)
        // if (typeof decoded.exp !== 'undefined') {
        //   throw Error;
        // }
        return decoded.exp
      } catch {
        return false
      }
    },
    userLoggedIn: state => {
      if (state.token !== null) {
        return jwt.decode(state.token).tokenStaff
      }
      return null
    },
    isJwtVerified: state => {
      try {
        const decoded = jwt.decode(state.token)
        const now = moment().valueOf() / 1000
        if (typeof decoded.exp !== 'undefined' && decoded.exp < now) {
          throw Error
        }
        return true
      } catch {
        return false
      }
    }
  },
  mutations: {
    sidenavmini(state, isMini) {
      state.isMini = isMini
    },
    apiVersion(state, version) {
      state.apiVersion = version
    },
    saveToken(state, token) {
      localStorage.setItem('token', token)
      state.token = token
    },
    removeToken(state) {
      state.token = null
    },
    SOCKET_CONNECT(state) {
      state.isConnected = true
      // console.log('Connected : ' + state)
    },
    SOCKET_DISCONNECT(state) {
      state.isConnected = false
      // console.log('Disconnected : ' + state)
    }
  },
  actions: {},
  modules: {}
})
