<template>
  <div>
    <v-progress-linear
      v-if="loading"
      :size="400"
      :width="10"
      color="#1E88E5"
      indeterminate
    ></v-progress-linear>
    <transition name="dashboard">
      <div v-if="!loading" class="main-div">
        <!-- <h1>Data Staff</h1> -->
        <div class="search">
          <v-menu v-model="menu" :close-on-content-click="false" :nudge-width="400" allow-overflow offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark v-bind="attrs" v-on="on">
                Filter
              </v-btn>
            </template>
            <v-card>
              <v-autocomplete
                v-if="isAdmin"
                :loading="loadingCompany"
                :items="filter.company"
                v-model="filter.selectedCompany"
                class="px-5"
                label="Filter BMT"
                placeholder="BMT"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete>
              <!-- <v-autocomplete
                :loading="loadingBranch"
                :items="filter.branch"
                v-if="isAdmin || isHeadquarters"
                v-model="filter.selectedBranch"
                class="px-5"
                label="Cabang BMT"
                placeholder="Cabang"
                item-value="value"
                item-text="text"
                single-line
                clearable
              ></v-autocomplete> -->
            </v-card>
          </v-menu>
          <v-spacer></v-spacer>
          <v-btn
            class="mx-2"
            fab
            small
            @click="
              table.search = ''
              getAllStaff()
            "
            color="primary"
          >
            <v-icon>
              mdi-autorenew
            </v-icon>
          </v-btn>
          <v-text-field
            v-model="table.search"
            prepend-icon="mdi-magnify"
            class="input"
            label="Cari..."
            single-line
            hide-details
          ></v-text-field>
        </div>

        <v-data-table
          :headers="table.headers"
          :items="staffs"
          :loading="loading"
          :page.sync="table.pagination.page"
          :items-per-page="table.pagination.itemsPerPage"
          item-key="id"
          hide-default-footer
          height="500px"
          class="elevation-0 pt-5"
          dense
        >
          <template v-slot:[`item.isActive`]="{ item }">
            <v-icon v-if="item.isActive" color="green">
              mdi-check-outline
            </v-icon>
            <v-icon v-else color="red">
              mdi-close-outline
            </v-icon>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-menu bottom origin="center center" transition="scale-transition">
              <template v-slot:activator="{ on: menu, attrs }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on: tooltip }">
                    <v-btn icon color="primary" dark v-bind="attrs" v-on="{ ...tooltip, ...menu }">
                      <v-icon dark>
                        mdi-menu
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>Menu</span>
                </v-tooltip>
              </template>
              <v-list dense>
                <v-subheader>{{ item.name }}</v-subheader>
                <v-list-item @click="onClickFooter(0, item.id)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ubah data staff</v-list-item-title>
                </v-list-item>
                <v-list-item @click="resetPassword(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-account-key-outline</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Ganti Password</v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteStaff(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-close</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Hapus Karyawan</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>

        <div class="footer">
          <div class="datatable-action">
            <v-pagination
              v-model="table.pagination.page"
              :length="pages"
              :total-visible="5"
              @input="getAllSubscription()"
            />
          </div>
          <v-spacer></v-spacer>

          <template>
            <v-dialog v-model="dialog" max-width="1200">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" fab dark v-bind="attrs" v-on="on" @click="onClickFooter(0, '')">
                  <v-icon dark>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </template>
              <staff
                :id_staff="selectedStaff"
                v-if="selectedForm == 0"
                @change-form="changeForm"
                @close-form="closeForm"
              />
            </v-dialog>
          </template>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'
import Staff from './components/Staff'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')

export default {
  components: {
    Staff
  },
  mixins: [ConstantMixin],
  data() {
    return {
      loading: true,
      isAdmin: false,
      staffs: [],
      staffNewStatus: false,
      selectedStaff: '',
      dialog: '',
      selectedForm: null,
      pages: 0,
      menu: false,
      table: {
        search: '',
        headers: [
          { text: 'Nama', value: 'name', width: '120px', sortable: false },
          { text: 'Telepon', value: 'phone', width: '80px', sortable: false },
          { text: 'BMT', value: 'company', width: '140px', sortable: false },
          { text: 'Role', value: 'role', width: '100px', sortable: false },
          { text: 'Aktif', value: 'isActive', width: '50px', sortable: false },
          { text: '', value: 'actions', width: '10px', sortable: false }
        ],
        pagination: {
          descending: false,
          page: 1,
          itemsPerPage: 10,
          sortBy: ''
        }
      },
      filter: {
        selected: '',
        list: []
      }
    }
  },
  watch: {
    'table.search'() {
      this.searchData(this)
    },
    dialog(newValue) {
      if (!newValue) {
        this.selectedForm = null
      }
    },
    'filter.selectedCompany'(value) {
      this.table.pagination.page = 1
      this.getAllStaff()
      // this.getAllSubscriptionHeader()
      this.getBranchCompany(value)
    },
    'filter.selectedBranch'(value) {
      this.table.pagination.page = 1
      this.getAllStaff()
      // this.getAllSubscriptionHeader()
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.getAllStaff()
      if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
        this.isAdmin = true
        // this.table.headers.unshift({ text: 'BMT', value: 'companyName', width: '150px' })
      } else if (this.$store.getters.userLoggedIn.company.isHeadquarters) {
        this.isHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
        this.getBranchCompany(this.$store.getters.userLoggedIn.company.id)

        // this.table.headers.unshift({ text: 'Cabang', value: 'branch', width: '150px' })
      }
      this.getAllCompany()
    })
  },
  methods: {
    searchData: _.debounce(v => {
      v.getAllStaff()
    }, 650),
    getAllCompany() {
      this.loadingCompany = true
      this.runApiCalls(() => {
        axios.get(Constant.apiUrl.concat('/master/company?headquarters=1')).then(response => {
          if (response.status == 200) {
            const companyDataGet = response.data.data
            this.filter.company = companyDataGet
              .filter(v => v.name !== 'ADMINISTRATOR')
              .map(company => {
                return {
                  value: _.get(company, '_id', '').toString(),
                  text: _.get(company, 'name', '')
                }
              })
          }
          this.loadingCompany = false
        })
      })
    },
    getBranchCompany(id_company) {
      this.loadingBranch = true
      this.runApiCalls(() => {
        if (id_company) {
          axios.get(Constant.apiUrl.concat(`/master/company/branch/${id_company}`)).then(response => {
            if (response.status == 200) {
              const companyDataGet = response.data.data
              this.filter.branch = companyDataGet
                .filter(v => v.name !== 'ADMINISTRATOR')
                .map(company => {
                  return {
                    value: _.get(company, '_id', '').toString(),
                    text: _.get(company, 'headquarters.description', '')
                  }
                })

              if (this.isAdmin) {
                this.filter.branch.unshift({ value: this.filter.selectedCompany, text: 'PUSAT' })
                this.filter.branch.unshift({ value: '', text: 'ALL' })
              } else {
                this.filter.branch.unshift({
                  value: this.$store.getters.userLoggedIn.company.id,
                  text: 'PUSAT'
                })
                this.filter.selectedBranch = this.$store.getters.userLoggedIn.company.id
              }
            }
            this.loadingBranch = false
          })
        } else {
          this.loadingBranch = false
          this.filter.selectedBranch = ''
          this.filter.branch = []
        }
      })
    },
    getAllStaff(option) {
      let company = !this.isAdmin ? this.$store.getters.userLoggedIn.company.id : ''
      if (this.filter.selectedCompany) company = this.filter.selectedCompany
      if (this.filter.selectedBranch)
        company =
          this.filter.selectedBranch == 'Pusat'
            ? this.$store.getters.userLoggedIn.company.id
            : this.filter.selectedBranch

      let isHq = ''
      if (
        (this.isHeadquarters || this.isAdmin) &&
        !this.filter.selectedBranch &&
        this.filter.selectedBranch != this.filter.selectedCompany
      )
        isHq = '1'
      this.runApiCalls(() => {
        axios
          .get(
            Constant.apiUrl.concat(
              '/master/staff?page=',
              this.table.pagination.page,
              '&itemCount=',
              this.table.pagination.itemsPerPage,
              '&sort=detail.name',
              '&search=',
              this.table.search,
              '&company=',
              company || '',
              '&periode=',
              this.filter.selected || '',
              '&headquarters=',
              isHq
            )
          )
          .then(response => {
            if (response.status == 200) {
              const { data, length } = response.data
              this.pages = 0
              this.staffs = []
              this.pages = Math.ceil(length / this.table.pagination.itemsPerPage)
              this.staffs = data
                .filter(v => _.get(v, 'detail.role', '').toLowerCase() !== 'administrator')
                .map(staff => {
                  return {
                    id: _.get(staff, '_id', '').toString(),
                    username: _.get(staff, 'username', ''),
                    name: _.get(staff, 'detail.name', ''),
                    nik: _.get(staff, 'detail.nik', ''),
                    phone: _.get(staff, 'detail.phone', ''),
                    company: _.get(staff, 'id_company.name', ''),
                    role: _.get(staff, 'detail.role', '')
                      .toString()
                      .toUpperCase(),
                    isActive: _.get(staff, 'detail.isActive', '').toString()
                  }
                })
            } else {
              this.pages = 0
              this.staffs = []
            }
            this.loading = false
          })
      })
    },
    resetPassword(item) {
      const id = item.id
      Swal.fire({
        title: 'Ganti Kata Sandi',
        html:
          '<label>Minimal 8 karakter</label><div></div>' +
          '<label>kombinasi huruf kapital, huruf kecil, angka</label>' +
          '<input id="password" type="password" placeholder="Kata sandi baru" class="swal2-input">' +
          '<input id="rePassword" type="password" placeholder="Ulang kata sandi baru" class="swal2-input">',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Simpan',
        cancelButtonText: 'Batal'
      }).then(result => {
        if (result.value) {
          const pass = document.getElementById('password').value
          const rePass = document.getElementById('rePassword').value
          if (pass === rePass) {
            // /^
            //   (?=.*\d)          // should contain at least one digit
            //   (?=.*[a-z])       // should contain at least one lower case
            //   (?=.*[A-Z])       // should contain at least one upper case
            //   [a-zA-Z0-9!@#$%&?"']{8,}   // should contain at least 8 from the mentioned characters
            // $/
            if (pass.match(Constant.passwordAuth)) {
              axios
                .post(Constant.apiUrl.concat('/master/staff/reset'), {
                  id,
                  password: pass
                })
                .then(response => {
                  if (response.status === 200) {
                    Swal.fire({
                      icon: 'success',
                      title: 'Berhasil',
                      text: 'Kata sandi berhasil diperbarui'
                    })
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
            } else {
              Swal.fire({
                icon: 'error',
                title: 'Terjadi Kesalahan',
                text: 'Kata sandi tidak sesuai ketentuan'
              })
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Terjadi Kesalahan',
              text: 'Kata sandi tidak sama'
            })
          }
        }
      })
    },
    deleteStaff(items) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: 'Data Karyawan akan dihapus',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          if (items.status === true) {
            this.staffNewStatus = false
          } else {
            this.staffNewStatus = true
          }
          axios
            .post(Constant.apiUrl.concat('/master/staff/delete/', items.id))
            .then(response => {
              if (response.status === 200) {
                Swal.fire('Berhasil', 'Karyawan berhasil dihapus', 'success')
                this.getAllStaff()
              }
            })
            .catch(error => {
              if (error.response) {
                const message = error.response.data.pesan
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: message
                })
              } else if (error.request) {
                Swal.fire({
                  icon: 'error',
                  title: 'Terjadi Kesalahan',
                  text: 'Tidak dapat menyambung ke server'
                })
              } else {
                // eslint-disable-next-line
                console.log('Something happened in the middle of request')
              }
            })
        }
      })
    },
    onClickFooter(index, id_staff) {
      this.selectedStaff = id_staff
      switch (index) {
        default:
          this.changeForm(index)
          break
      }
      this.dialog = true
    },
    togglePropsExpanded(props) {
      this.$set(props, 'expanded', !props.expanded)
    },

    changeForm(item) {
      this.selectedForm = item
    },
    closeForm() {
      this.dialog = false
      this.getAllStaff()
    }
  }
}
</script>

<style lang="scss" scoped>
.dashboard-enter {
  opacity: 0;
  transform: translateX(-100px);
}
.dashboard-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
.dashboard-enter-active {
  transition: all 0.3s ease-in;
}

.main-div {
  margin: 10px auto;
  width: 90%;
}

h1 {
  font-size: 1.5em;
  text-align: left;
}

.search {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  right: 0;
}

.input {
  max-width: 30%;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
</style>
