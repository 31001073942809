<template>
  <main class="main ma-5">
    <v-row no-gutters class="d-flex justify-center">
      <div class="header d-flex justify-center">
        <h1>LABA-RUGI</h1>
      </div>
    </v-row>
    <v-row no-gutters class="mt-n10 d-flex justify-center">
      <v-col cols="1"> Periode :</v-col>
      <v-col cols="1">
        <v-select class="mt-n6" v-model="selectedPeriode.day" :items="listPeriode.day"> </v-select>
      </v-col>
      <v-col cols="2">
        <v-select class="ml-5 mt-n6" v-model="selectedPeriode.month" :items="listPeriode.month"> </v-select>
      </v-col>
      <v-col cols="1">
        <v-select class="ml-5 mt-n6" v-model="selectedPeriode.year" :items="listPeriode.year"> </v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-progress-linear :active="isLoading" indeterminate rounded height="6"></v-progress-linear>
    </v-row>
    <v-row class="d-flex justify-space-between" v-if="listProfitAndLoss.length > 0">
      <v-col id="debit" class="">
        <!-- <v-list dense>
          <v-list-item v-for="(item, i) in listProfitAndLoss" :key="i">
            <v-list-item-content>
              <v-list-item-title v-text="item.id_account.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list> -->

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Kode</th>
                <th class="text-left">Pos</th>

                <th class="text-left"></th>
                <th class="text-left"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in listProfitAndLoss" :key="item._id">
                <td v-if="!item.isNameBold">{{ item.code }}</td>
                <td v-if="item.isNameBold">
                  <h3>
                    <b>{{ item.code }}</b>
                  </h3>
                </td>

                <td v-if="!item.isNameBold" class="pl-10">{{ item.name }}</td>

                <td v-if="item.isNameBold && item.isCenter" class="d-flex justify-center">
                  <h2>
                    <b>{{ item.name }}</b>
                  </h2>
                </td>
                <td v-if="item.isNameBold && !item.isCenter">
                  <h3>
                    <b>{{ item.name }}</b>
                  </h3>
                </td>

                <td v-if="!item.isNominalBold">
                  {{ item.isCenter ? '' : getCurrency(item.nominal) }}
                </td>
                <td v-if="item.isNominalBold">
                  <b>{{ getCurrency(item.nominal) }}</b>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </main>
</template>

<script>
import Constant from '@/const'
import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const moment = require('moment')
const axios = require('axios')

export default {
  name: 'ProfitLoss',
  components: {},
  mixins: [ConstantMixin],
  data() {
    return {
      menuCode: 'b-02',
      isLoading: true,
      listProfitAndLoss: [],

      isCurrentDate: true,
      selectedPeriode: {
        day: '',
        year: '',
        month: ''
      },
      listPeriode: {
        day: [],
        year: [],
        month: []
      }
    }
  },
  watch: {
    selectedPeriode: {
      handler: function(after, before) {
        this.getCutOff()
      },
      deep: true
    }
  },
  mounted() {
    this.$nextTick(() => {
      let i = 0
      while (i < 10) {
        this.listPeriode.year.push(
          moment()
            .add(i * -1, 'year')
            .format('YYYY')
        )
        i++
      }
      i = 0
      let month = moment()
        .startOf('year')
        .format()
      while (i < 12) {
        this.listPeriode.month.push({
          text: moment(month)
            .locale('id')
            .format('MMMM'),
          value: moment(month)
            .locale('id')
            .format('M')
        })
        month = moment(month)
          .add(1, 'month')
          .format()
        i++
      }

      i = 1
      while (i < 32) {
        this.listPeriode.day.push(i.toString())
        i++
      }

      this.selectedPeriode.year = moment().format('YYYY')
      this.selectedPeriode.month = moment().format('M')
      this.selectedPeriode.day = moment().format('D')

      this.isCurrentDate =
        moment()
          .locale('id')
          .format('D-M-YYYY') ==
        `${this.selectedPeriode.day}-${this.selectedPeriode.month}-${this.selectedPeriode.year}`

      this.getCutOff()
    })
  },
  methods: {
    getCutOff() {
      try {
        this.isLoading = true
        this.isCurrentDate =
          moment()
            .locale('id')
            .format('D-M-YYYY') ==
          `${this.selectedPeriode.day}-${this.selectedPeriode.month}-${this.selectedPeriode.year}`
        this.runApiCalls(() => {
          axios
            .get(
              Constant.apiUrl.concat(
                '/accounting/chartaccount/cutoff?date=',
                moment(
                  `${this.selectedPeriode.year}-${this.selectedPeriode.month}-${this.selectedPeriode.day}`
                ).format('YYYY-M-D')
              )
            )
            .then(response => {
              this.listProfitAndLoss = []

              this.isLoading = false
              if (response.status == 200) {
                let { data } = response.data

                //! Split code to easy grouping and get nominal by filter
                data.item = data.item.map(m => {
                  return {
                    ...m,
                    splitCode: m.id_account.code.split('.'),
                    nominal: this.isCurrentDate ? m.id_account.nominal : m.nominal
                  }
                })

                let profitAndLossGroup = data.item.filter(
                  v =>
                    ['3', '4', '5', '6', '7'].includes(v.splitCode[0]) &&
                    v.splitCode[1] != '0' &&
                    v.splitCode[2] == '0'
                )

                for (let i = 0; i < profitAndLossGroup.length; i++) {
                  const itemGroup = profitAndLossGroup[i]
                  if (i == 0)
                    this.listProfitAndLoss.push({
                      code: '',
                      name: data.item
                        .find(
                          v =>
                            v.splitCode[0] == itemGroup.splitCode[0] &&
                            v.splitCode[1] == '0' &&
                            v.splitCode[2] == '0'
                        )
                        .id_account.name.toUpperCase(),
                      nominal: '',
                      isNameBold: true,
                      isCenter: true
                    })
                  else {
                    if (profitAndLossGroup[i].splitCode[0] != profitAndLossGroup[i - 1].splitCode[0])
                      this.listProfitAndLoss.push({
                        code: '',
                        name: data.item
                          .find(
                            v =>
                              v.splitCode[0] == itemGroup.splitCode[0] &&
                              v.splitCode[1] == '0' &&
                              v.splitCode[2] == '0'
                          )
                          .id_account.name.toUpperCase(),
                        nominal: '',
                        isNameBold: true,
                        isCenter: true
                      })
                  }

                  this.listProfitAndLoss.push({
                    code: itemGroup.id_account.code,
                    name: itemGroup.id_account.name,
                    nominal: '',
                    isNameBold: true
                  })

                  for (let j = 0; j < data.item.length; j++) {
                    const item = data.item[j]
                    const splitCode = item.id_account.code.split('.')
                    if (item.id_account.code == itemGroup.id_account.code) continue
                    if (splitCode[0] == itemGroup.splitCode[0] && splitCode[1] == itemGroup.splitCode[1]) {
                      this.listProfitAndLoss.push({
                        code: item.id_account.code,
                        name: item.id_account.name,
                        nominal: item.nominal,
                        isTab: true
                      })
                    }
                  }
                  this.listProfitAndLoss.push({
                    code: '',
                    name: `Jumlah ${itemGroup.id_account.name}`,
                    nominal: itemGroup.nominal,
                    isNominalBold: true
                  })

                  if (itemGroup.splitCode[0] == '5') {
                    const income = profitAndLossGroup.find(v => v.splitCode[0] == '4')?.nominal || 0
                    const hpp = profitAndLossGroup.find(v => v.splitCode[0] == '5')?.nominal || 0
                    this.listProfitAndLoss.push({
                      code: '',
                      name: 'Penjualan Bersih',
                      nominal: income - hpp,
                      isNameBold: true,
                      isTab: true
                    })
                  } else if (itemGroup.splitCode[0] == '6') {
                    const income = profitAndLossGroup.find(v => v.splitCode[0] == '4')?.nominal || 0
                    const hpp = profitAndLossGroup.find(v => v.splitCode[0] == '5')?.nominal || 0
                    const cost = profitAndLossGroup.find(v => v.splitCode[0] == '6')?.nominal || 0

                    this.listProfitAndLoss.push({
                      code: '',
                      name: 'Laba Bersih Usaha',
                      nominal: income - hpp - cost,
                      isNameBold: true,
                      isTab: true
                    })
                  } else if (itemGroup.splitCode[0] == '7' && itemGroup.splitCode[1] == '2') {
                    const income = profitAndLossGroup.find(v => v.splitCode[0] == '4')?.nominal || 0
                    const otherIncome =
                      profitAndLossGroup.find(v => v.splitCode[0] == '7' && v.splitCode[1] == '1')?.nominal ||
                      0
                    const hpp = profitAndLossGroup.find(v => v.splitCode[0] == '5')?.nominal || 0
                    const cost = profitAndLossGroup.find(v => v.splitCode[0] == '6')?.nominal || 0
                    const otherCost =
                      profitAndLossGroup.find(v => v.splitCode[0] == '7' && v.splitCode[1] == '2')?.nominal ||
                      0

                    this.listProfitAndLoss.push({
                      code: '',
                      name: 'Laba Bersih Usaha Sebelum Pajak',
                      nominal: income - hpp - cost + otherIncome - otherCost,
                      isNameBold: true,
                      isTab: true
                    })
                  }

                  this.listProfitAndLoss.push({
                    code: '',
                    name: '',
                    nominal: '',
                    isCenter: true
                  })
                }
              }
            })
            .catch(error => {
              this.listProfitAndLoss = []
              this.isLoading = false

              // console.log(error)
            })
        })
      } catch (error) {
        this.isLoading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: 100px;
  width: 300px;
}
.left {
  background-color: greenyellow;
}
.right {
  background-color: blueviolet;
}
</style>
