<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ this.title }}</span>
      <v-btn absolute right fab @click="onCloseButtonClick()" small text color="primary">
        <v-icon dark>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row no-gutters>
          <v-col cols="12">
            <v-row>
              <v-col cols="5">
                <div class="detail-input">
                  <v-text-field
                    id="name"
                    label="Pencarian Cabang"
                    v-model="table.search"
                    dense
                    placeholder="Cari Cabang . . ."
                    class="detail-input__input-text"
                  /></div
              ></v-col>
              <v-col offset="2" cols="5">
                <div class="detail-input">
                  <v-currency-field
                    id="total"
                    readonly
                    label="Total Pembayaran"
                    v-model="total"
                    dense
                    prefix="Rp."
                    class="detail-input__input-text"
                  /></div
              ></v-col>
            </v-row>
            <v-row>
              <v-col md="12">
                <v-data-table
                  :headers="table.headers"
                  :items="subscriptions"
                  :loading="loading"
                  :search="table.search"
                  class="elevation-0"
                  hide-default-footer
                  disable-pagination
                >
                </v-data-table>
              </v-col>
            </v-row>

            <v-divider></v-divider>
            <br />
            <v-container>
              <br />
              <v-divider></v-divider>
              <v-row>
                <v-col cols="12">
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="secondary" width="100" text large @click="onCloseButtonClick()">
                      Kembali
                    </v-btn>
                  </v-card-actions>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import Swal from 'sweetalert2'
import Constant from '@/const'

import ConstantMixin from '@/constantMixin'

const _ = require('lodash')
const axios = require('axios')
const moment = require('moment')

export default {
  mixins: [ConstantMixin],
  props: {
    id_payment: String,
    isSave: Boolean
  },
  data: () => ({
    title: 'Permintaan Pembayaran',
    loading: false,
    loadingSubs: false,
    loadingUpload: false,
    subscriptions: [],
    selectedSubs: [],
    dialog: false,
    dialogRemove: false,
    isNew: true,
    isConfirm: false,
    isUserHeadquarters: false,
    isAdmin: false,
    load: true,
    templates: {
      title: 'Bukti Transfer',
      isEnable: true,
      files: [],
      failedFiles: []
    },
    table: {
      search: '',
      headers: [
        { text: 'Cabang', value: 'name', width: '100px' },
        { text: 'Jumlah Penagihan Iuran', value: 'value', width: '100px' }
      ],
      pagination: {
        descending: false,
        itemsPerPage: -1,
        sortBy: ''
      }
    },
    total: 0,
    input: {
      isDemo: false,
      isHeadquarters: false,
      batch: '',
      id_company: '',
      subscription: [],
      isConfirm: false,
      timestamps: {
        created_by: ''
      }
    }
  }),
  computed: {},
  watch: {},

  mounted() {
    if (_.toLower(this.$store.getters.userLoggedIn.role) == 'administrator') {
      this.isAdmin = true
      this.table.headers.push({ text: '', value: 'actions', width: '30px' })
    }

    if (this.id_payment != '') {
      this.title = 'Detail Penagihan'
      this.isNew = false
      this.getPayment()
    } else {
      this.input.id_company = this.$store.getters.userLoggedIn.company.id
    }
    this.isUserHeadquarters = this.$store.getters.userLoggedIn.company.isHeadquarters
    if (!this.isUserHeadquarters) this.table.headers.splice(1, 1)
    this.input.isDemo = this.isDemo
  },
  methods: {
    removeSubscription(item) {
      Swal.fire({
        title: 'Anda Yakin ?',
        text: `Menghapus ${item.memberName} dari Daftar pembayaran`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak'
      }).then(result => {
        if (result.value) {
          this.swalLoading()
          this.runApiCalls(() => {
            axios
              .put(Constant.apiUrl.concat('/payment/remove/', this.id_payment), {
                id_subscription: item.id,
                id_staff: this.$store.getters.userLoggedIn.id
              })
              .then(response => {
                if (response.status === 200) {
                  this.getPayment()
                  this.swalToast('success', 'Berhasil menghapus peserta')
                }
              })
              .catch(error => {
                if (error.response) {
                  const message = error.response.data.pesan
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: message
                  })
                } else if (error.request) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Terjadi Kesalahan',
                    text: 'Tidak dapat menyambung ke server'
                  })
                } else {
                  // eslint-disable-next-line
                  console.log('Something happened in the middle of request')
                }
              })
          })
        }
      })
    },
    getPayment() {
      try {
        this.loading = true
        axios.get(Constant.apiUrl.concat('/payment/', this.id_payment)).then(response => {
          const payment = response.data.data
          if (!_.get(payment, 'isHeadquarters', false)) this.table.headers.splice(1, 1)

          this.subscriptions = _.get(payment, 'subscription', '')
            .filter(
              v =>
                v.companyName == this.$store.getters.userLoggedIn.company.branchName ||
                this.isUserHeadquarters
            )
            .map(s => {
              this.total += s.tariffNominal

              return {
                companyName: s.companyName,
                tariffNominal: s.tariffNominal
              }
            })

          let grouped = _.groupBy(this.subscriptions, 'companyName')

          this.subscriptions = []

          for (const key in grouped) {
            this.subscriptions.push({
              name: key,
              value: this.getCurrency(_.sumBy(grouped[key], 'tariffNominal'))
            })
          }

          this.subscriptions = _.sortBy(this.subscriptions, ['name'])

          this.loading = false
          if (_.get(payment, 'bill_document')) this.templates.files[0] = _.get(payment, 'bill_document')
          else
            this.templates = {
              title: 'Bukti Transfer',
              isEnable: true,
              files: [],
              failedFiles: []
            }(this.templates)
          this.isConfirm = !this.input.isConfirm
        })
      } catch (error) {
        this.loading = false
      }
    },
    savePayment() {
      try {
        Swal.fire({
          title: 'Anda Yakin ?',
          text: `Pastikan sudah sesuai sebelum melanjutkan`,
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya',
          cancelButtonText: 'Tidak'
        }).then(result => {
          if (result.value) {
            this.runApiCalls(() => {
              axios
                .put(
                  Constant.apiUrl.concat(
                    '/payment/',
                    this.id_payment,
                    '/',
                    this.$store.getters.userLoggedIn.id
                  )
                )
                .then(response => {
                  if (response.status === 200) {
                    this.onCloseButtonClick()
                    // this.$socket.emit('payment:update', {
                    //   staff: this.$store.getters.userLoggedIn,
                    //   company: this.input.id_company
                    // })
                    Swal.fire('Berhasil', 'Data Iuran Peserta Telah Dikonfirmasi', 'success')
                  }
                })
                .catch(error => {
                  if (error.response) {
                    const message = error.response.data.pesan
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: message
                    })
                  } else if (error.request) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Terjadi Kesalahan',
                      text: 'Tidak dapat menyambung ke server'
                    })
                  } else {
                    // eslint-disable-next-line
                    console.log('Something happened in the middle of request')
                  }
                })
            })
          }
        })
      } catch (error) {
        Swal.fire({
          icon: 'error',
          title: 'Terjadi Kesalahan',
          text: 'Gagal menyimpan payment!',
          footer: '<a href>Please contact administrator</a>'
        })
      }
    },
    onCloseButtonClick() {
      this.$emit('close-form')
    },
    handleFilesUpload() {
      const filesUploaded = this.$refs.files.files[0]
      if (!this.verify(filesUploaded)) {
        if (this.templates.files.length > 0) this.templates.files = []
        this.templates.files.push(filesUploaded)
        this.submitFiles()
      } else {
        if (this.templates.failedFiles.length > 0) this.templates.failedFiles = []
        this.templates.failedFiles.push({
          file: filesUploaded,
          message: this.verify(filesUploaded)
        })
      }
      this.$refs.files.value = ''
    },
    async submitFiles(isDelete) {
      this.loadingUpload = true
      const formData = new FormData()
      this.templates.files.forEach(file => {
        formData.append(`file`, file.isExist ? JSON.stringify(file) : file)
      })
      formData.append('id_payment', this.id_payment)

      await this.runApiCalls(async () => {
        await axios
          .post(Constant.apiUrl.concat('/payment/upload'), formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })
          .then(response => {
            if (response.status === 200) {
              this.loadingUpload = false
              this.getPayment()
              Swal.fire(
                'Berhasil!',
                isDelete ? 'Dokumen berhasil dihapus' : 'Dokumen berhasil diunggah',
                'success'
              )
            }
          })
          .catch(error => {
            this.loadingUpload = false
            this.templates.files.splice(0, 1)
            this.showErrorAxios(error)
          })
      })
    },
    addFiles() {
      this.$refs.files.click()
    },
    previewFile(file) {
      let url = ''
      if (typeof file === 'object') url = Constant.apiUrl.concat(`/${file.path}`)
      else url = URL.createObjectURL(file)
      window.open(url)
    },
    removeFiles() {
      this.templates.files.splice(0, 1)
    },
    async deleteFiles() {
      this.templates.files[0].isDelete = !this.templates.files[0].isDelete
      // this.getPayment()
      await this.submitFiles(true)

      this.templates.files.splice(0, 1)
    },
    removeWarning() {
      this.templates.failedFiles.splice(0, 1)
    },
    verify(file) {
      const maxSize = 10000000
      const allowedTypes = ['image/png', 'image/jpeg', 'application/pdf']
      if (file.size > maxSize) {
        return 'Maksimum Ukuran file 10 MB'
      }

      const ext = file.name.split('.')

      if (!allowedTypes.includes(file.type)) {
        return `Tipe file .${ext[1]} tidak diperbolehkan`
      }

      return ''
    }
  }
}
</script>

<style lang="scss" scoped>
.detail-radio {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.detail-date {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.label-header {
  color: #222;
}

.detail-input {
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  &__label {
    flex: 1;
    display: flex;
    color: #222;
  }

  &__input-text {
    margin-left: 10px;
    max-width: 60%;
  }
}

input:placeholder-shown {
  font-size: 0.5em;
}
</style>
