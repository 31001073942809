<template>
  <div class="home">
    <section class="login-page">
      <login-box></login-box>
    </section>
  </div>
  <!-- <div class="login-page">
    <div class="title">
      <h3 class="h3">Sistem Informasi Manajemen</h3>
      <h2 class="h2">Rumah Sakit PKU Muhammadiyah</h2>
      <h3 class="h3 sub-title">Wonosobo</h3>
    </div>
  </div> -->
</template>

<script>
import LoginBox from './components/LoginBox.vue'
import ConstantMixin from '@/constantMixin'

export default {
  name: 'LoginPage',
  mixins: [ConstantMixin],
  components: {
    LoginBox
  }
}
</script>

<style lang="scss" scoped>
$blue-gradient: #3498db;

.home {
  background: linear-gradient(
    to bottom right,
    hsl(207.06, 100%, 80%) 0%,
    hsl(207.52, 99.22%, 79.58%) 8.1%,
    hsl(208.75, 97.18%, 78.4%) 15.5%,
    hsl(210.49, 94.43%, 76.57%) 22.5%,
    hsl(212.49, 91.46%, 74.22%) 29%,
    hsl(214.55, 88.6%, 71.46%) 35.3%,
    hsl(216.52, 86.02%, 68.4%) 41.2%,
    hsl(218.33, 83.78%, 65.17%) 47.1%,
    hsl(219.93, 81.89%, 61.88%) 52.9%,
    hsl(221.31, 80.33%, 58.65%) 58.8%,
    hsl(222.47, 79.06%, 55.6%) 64.7%,
    hsl(223.42, 78.06%, 52.84%) 71%,
    hsl(224.16, 77.29%, 50.48%) 77.5%,
    hsl(224.7, 80.97%, 48.66%) 84.5%,
    hsl(225.03, 84.53%, 47.48%) 91.9%,
    hsl(225.15, 85.83%, 47.06%) 100%
  );
  // };linear-gradient(-45deg, #285bbd 0%, #1f8ce5 46%, #76e7ff 100%);
  background-size: 600% 600%;
  animation: gradient 15s ease infinite;
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  font-size: 2vh;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow: hidden;
}

.login-page {
  background: white;
  width: 45vw;
  height: 100%;
  padding: 3em 3em 3em 9em;
}

.hero {
  width: 60vw;
  padding: 2em;
  position: relative;
}

.background {
  z-index: 1;
  position: absolute;
  top: 20%;
  left: 20%;
  height: 22em;
  animation: background 1s ease-in-out;
}

@keyframes background {
  0% {
    top: -50%;
  }
  70% {
    top: 45%;
  }
  100% {
    top: 20%;
  }
}

.character {
  z-index: 1;
  position: absolute;
  top: 28%;
  left: 45%;
  height: 18em;
  animation: character 2s ease-in-out;
}

@keyframes character {
  0% {
    left: 200%;
  }
  100% {
    left: 45%;
  }
}
.character-shadow {
  z-index: 0;
  position: absolute;
  top: 62%;
  left: 18%;
  height: 2em;
  animation: character-shadow 2s ease-in-out;
}

@keyframes character-shadow {
  0% {
    left: 200%;
  }
  100% {
    left: 18%;
  }
}

.background-lines {
  z-index: 0;
  position: absolute;
  top: 35%;
  left: 0%;
  width: 100%;
  animation: lines 3s linear;
}

@keyframes lines {
  0% {
    top: 60%;
    opacity: 0;
  }
  70% {
    top: 40%;
    opacity: 0.2;
  }
  100% {
    top: 35%;
    opacity: 0.9;
  }
}

.icons {
  z-index: 2;
  animation: icon 4s linear;
}

.icon {
  z-index: 2;
  position: absolute;
  height: 3.5em;
  animation: pulse 3s infinite ease-in-out;
  &.first {
    top: 38%;
    left: 35%;
    animation-delay: 0.5s;
  }
  &.second {
    top: 28%;
    left: 32%;
    animation-delay: 1s;
  }
  &.third {
    top: 16.5%;
    left: 36%;
    height: 4.5em;
    animation-delay: 1.5s;
  }
  &.fourth {
    top: 12%;
    left: 48%;
    animation-delay: 2s;
  }
  &.fifth {
    top: 17%;
    left: 61%;
    animation-delay: 2.5s;
  }
  &.sixth {
    top: 28%;
    left: 66%;
    height: 4em;
    animation-delay: 3s;
  }
  &.seventh {
    top: 41%;
    left: 61%;
    height: 4em;
    animation-delay: 3.5s;
  }
}

.title {
  position: absolute;
  top: 72.5%;
  left: 17%;
  color: white;
  font-size: 2.7rem !important;
  font-weight: 300;
  text-align: left;
  letter-spacing: 1.5em;
}
.subtitle {
  position: absolute;
  top: 67.5%;
  left: 17%;
  color: white;
  font-size: 1.5rem;
  font-weight: 50;
}
.version {
  position: absolute;
  top: 97%;
  left: 35%;
  color: white;
  font-size: 0.8rem;
  font-weight: 50;
}
.main {
  opacity: 0;
  animation: title 1s ease-in;
  animation-delay: 2s;
  animation-fill-mode: both;
}

@keyframes title {
  0% {
    top: 150%;
    opacity: 0;
  }
  50% {
    top: 100%;
    opacity: 0.2;
  }
  100% {
    top: 78%;
    opacity: 1;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(-360deg);
  }
}

@keyframes pulse {
  0% {
    z-index: 2;
    transform: scale(0.9);
  }

  50% {
    z-index: 2;
    transform: scale(1.1);
  }
  100% {
    z-index: 2;
    transform: scale(0.9);
  }
}
@keyframes icon {
  0% {
    opacity: 0;
  }
  90% {
    z-index: 2;
    opacity: 0;
  }

  100% {
    z-index: 2;
    opacity: 1;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

// .login-page {
//   min-height: 100vh;
//   background-image: url('~@/assets/image/login-background.png');
//   background-repeat: no-repeat;
//   background-size: cover;
// }
// .title {
//   position: absolute;
//   top: 30vh;
//   left: 12vw;
//   color: white;
// }
// .title > * {
//   margin: 2px;
// }
// .h3 {
//   font-family: 'Mont Extra Light', Helvetica, sans-serif;
// }
// .h2 {
//   font-family: 'Mont Heavy', Helvetica, sans-serif;
// }

// @media only screen and (max-width: 425px) {
//   .title {
//     position: initial;
//     padding-top: 25%;
//     background: linear-gradient(to bottom, $primary-color 0%, $blue-gradient 100%);
//   }
// }
</style>
