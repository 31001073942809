<script>
import { Line } from 'vue-chartjs'
export default {
  extends: Line,
  props: {
    chartData: {
      type: Object,
      required: false
    },
    options: {
      type: Object,
      required: true
    }
  },
  data() {
    return {}
  },
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
